import styled, { keyframes } from 'styled-components';
import { theme } from 'src/styles/theme';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 940px;
  margin: auto;
`;

const preloaderAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const StyledLoader = styled.div`
  display: inline-block;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border-width: 6px;
  border-style: solid;
  border-color: ${theme.colors.primary} rgba(20, 185, 179, 0.2) rgba(20, 185, 179, 0.2)
    rgba(20, 185, 179, 0.2);
  animation: ${preloaderAnimation} 0.6s 0s linear infinite;

  @media ${theme.device.laptop} {
    width: 50px;
    height: 50px;
  }
`;

export const StyledParagraph = styled.p`
  color: ${theme.colors.dark};
  font-weight: ${theme.typography.fontWeight.regular};
  font-size: ${theme.typography.fontSize.s};
  text-align: center;
  line-height: 24px;
  margin-top: 30px;

  @media ${theme.device.laptop} {
    max-width: 75%;
  }
`;
