import React from 'react';

export enum PlatformType {
  IOS = 'IOS',
  Android = 'Android',
  Web = 'Web',
}

export enum UserAction {
  YES = 'Yes',
  NO = 'No',
  I_DONT_KNOW = "I don't know",
}

export type EstimateType = [min: number, max: number];

export type PlatformsEstimate = { [platformType in PlatformType]: EstimateType };

export interface RawQuestionData {
  domain: string;
  description: string;
  groupedQuestions: Category[];
}

export interface DomainOption {
  label: string;
  description: string;
}

// Question
export type QuestionOption = {
  id: string;
  value: string;
  estimate: PlatformsEstimate;
  label?: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  checked?: boolean;
  disabled?: boolean;
};

export type Question = {
  id: string;
  label: string;
  selectedUserAction: UserAction[];
  categoryIndex: number;
  questionIndex: number;
  number: number;
  options: QuestionOption[];
  shortLabel?: string;
  isHide?: boolean;
  selectedOptions?: string[];
  single?: boolean;
  visited?: boolean;
};

export type Category = {
  id: string;
  label: string;
  questions: Question[];
};

// Navigation
export interface NavQuestion {
  id: string;
  label: string;
  visited: boolean;
  categoryIndex: number;
  questionIndex: number;
  number: number;
}

export interface NavCategory {
  id: string;
  label: string;
  visited: boolean;
  questions: NavQuestion[];
}

// Summary
export interface QuestionEstimate {
  id: string;
  label: string;
  estimates: PlatformsEstimate[];
}

export interface GroupedEstimate {
  id: string;
  label: string;
  questions: QuestionEstimate[];
}

// Report

export interface OptionReportType {
  label: string;
  min: number;
  max: number;
}

export interface QuestionReportType {
  label: string;
  options: OptionReportType[];
}

export interface CategoryReportType {
  label: string;
  questions: QuestionReportType[];
}

export interface PlatformReportType {
  label: string;
  categories: CategoryReportType[];
  min: number;
  max: number;
}

export interface GeneralQuestionReport {
  label: string;
  answer: string;
}
export interface GeneralCategoryReport {
  label: string;
  questions: GeneralQuestionReport[];
}

export interface ReportType {
  platforms: PlatformReportType[];
  general: GeneralCategoryReport[];
  unanswered: GeneralCategoryReport[];
  min: number;
  max: number;
  category: string;
}
