import styled from 'styled-components';
import { theme } from 'src/styles/theme';

const Button = styled.button`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  background-color: #212a31;
  color: white;
  border: none;
  background-image: none;
  box-shadow: none;
  cursor: pointer;
  text-transform: uppercase;
  padding: 15px 73px;
  margin-left: auto;

  opacity: 0.9;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }

  &:disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.6;
  }

  &.estimate {
    background-color: ${theme.colors.primary};
    color: white;
  }

  @media ${theme.device.laptop} {
    width: 100%;
    max-width: 260px;
    padding: 15px 30px;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: ${theme.index.middle};
    opacity: 1;
  }
`;

export default Button;
