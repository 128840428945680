import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useGlobalStore } from 'store/GlobalStore';
import { UserAction } from 'types/store';
import { PopUpEnum } from 'types/common';
import { AppRoutes } from 'src/constants';
import { useScrollPosition } from 'templates/Layout/Layout.hook';

import { Button, Container, Preloader, QuestionCount } from 'atoms';
import { PopUp } from 'molecules';
import {
  CalculatorNav,
  Question,
  PlatfromSummary,
  TotalSummaryMobile,
  QuestionsMobile,
} from 'organisms';
import {
  StyledContainer,
  StyledLeftSide,
  NavButtons,
  StyledBackButton,
  StyledSummary,
} from './Calculator.styles';

const Calculator: React.FC = () => {
  const { calculatorStore, isLoading, popUp, setPopUpAction } = useGlobalStore();

  const {
    platforms,
    currentQuestion,
    moveBack,
    moveNext,
    moveTo,
    isLastQuestion,
    isFirstQuestion,
    lastVisitedCategory,
    totalQuestions,
    setUserAction,
    setOptions,
    navigation,
    currentCategoryIndex,
    groupedEstimates,
    subtotal,
    total,
  } = calculatorStore;

  const navigate = useNavigate();
  const [containerClassName] = useScrollPosition();

  const moveToSummary = useCallback(() => {
    navigate(AppRoutes.SUMMARY);
  }, [navigate]);

  const moveToSelectPlatform = useCallback(() => {
    navigate(AppRoutes.SELECT_PLATFORMS);
  }, [navigate]);

  const renderNext = useCallback(
    () =>
      isLastQuestion ? (
        <Button className="estimate" onClick={moveToSummary}>
          Get esimation
        </Button>
      ) : (
        <Button onClick={moveNext} id="nextButton">
          Next
        </Button>
      ),
    [moveNext, isLastQuestion],
  );

  useEffect(() => {
    if (!currentQuestion) {
      navigate(AppRoutes.SELECT_DOMAIN);
    }

    window.scrollTo({ top: 0 });
  }, [navigate, currentQuestion]);

  const renderPopUpContent = useCallback(() => {
    switch (popUp) {
      case PopUpEnum.questions:
        return (
          <QuestionsMobile
            categories={navigation}
            currentCategoryIndex={currentCategoryIndex}
            lastVisitedCategory={lastVisitedCategory}
            moveTo={moveTo}
            handleClosePopUp={() => setPopUpAction(null)}
          />
        );
      case PopUpEnum.summary:
        return (
          <PlatfromSummary
            selectedPlatforms={platforms || []}
            groupedEstimates={groupedEstimates}
            subtotal={subtotal}
            total={total}
          />
        );
      default:
        return null;
    }
  }, [popUp]);

  if (isLoading) {
    return <Preloader />;
  }

  if (!currentQuestion) {
    return null;
  }

  return (
    <>
      <Container className={`mainWrapper ${containerClassName}`}>
        <StyledContainer>
          <StyledLeftSide>
            <CalculatorNav
              categories={navigation}
              currentCategoryIndex={currentCategoryIndex}
              lastVisitedCategory={lastVisitedCategory}
              moveTo={moveTo}
            />
            <NavButtons>
              <StyledBackButton onClick={isFirstQuestion ? moveToSelectPlatform : moveBack} />
              <QuestionCount
                current={currentQuestion.number}
                total={totalQuestions}
                handleOpenPopUp={() => setPopUpAction(PopUpEnum.questions)}
              />
            </NavButtons>
            <Question
              label={currentQuestion.label}
              selectedUserAction={currentQuestion.selectedUserAction || []}
              options={currentQuestion.options}
              single={currentQuestion.single}
              selectedOptions={currentQuestion.selectedOptions || []}
              onSelectAction={values => setUserAction(values as UserAction[])}
              setOptions={values => setOptions(values)}
              moveNext={renderNext}
            />
          </StyledLeftSide>
          {platforms && (
            <StyledSummary>
              <PlatfromSummary
                selectedPlatforms={platforms}
                groupedEstimates={groupedEstimates}
                subtotal={subtotal}
                total={total}
              />
            </StyledSummary>
          )}
        </StyledContainer>
      </Container>
      {/* Moblie */}
      <TotalSummaryMobile total={total} handleOpenPopUp={() => setPopUpAction(PopUpEnum.summary)} />
      {popUp && (
        <PopUp isOpen={popUp} setPopUpAction={setPopUpAction}>
          {renderPopUpContent()}
        </PopUp>
      )}
    </>
  );
};

export default observer(Calculator);
