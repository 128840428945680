import { theme } from 'src/styles/theme';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  background-color: ${theme.colors.backgroundGray};
  padding: 30px;
  margin-top: 30px;
  color: ${theme.colors.secondary};
  line-height: 24px;

  @media ${theme.device.laptop} {
    margin-top: auto;
    padding-bottom: 15vh;
  }
`;

export const StyledContent = styled.div`
  @media ${theme.device.laptop} {
    max-width: ${theme.width.mobileMax};
    margin: 0 auto;
  }
`;

export const StyledLink = styled.a`
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.s};
  line-height: 18px;
  color: ${theme.colors.dark};
  text-transform: uppercase;
  text-decoration: none;

  display: block;
  width: 100%;
  border: 4px solid ${theme.colors.dark};
  text-align: center;
  padding: 15px;
  margin-top: 30px;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  @media ${theme.device.laptop} {
    margin: 30px auto;
    max-width: 260px;
  }
`;
