import { theme } from 'src/styles/theme';
import styled from 'styled-components';

export const StyledContainer = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: ${theme.typography.fontWeight.semiBold};
  color: ${theme.colors.dark};
`;

export const StyledLabel = styled.div`
  font-size: ${theme.typography.fontSize.s};
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  padding: 5px 10px;
  min-width: 110px;
  border: 1px solid ${theme.colors.dark};
  margin-bottom: 5px;

  @media ${theme.device.laptop} {
    font-size: ${theme.typography.fontSize.xs};
    margin-bottom: 0;
  }
`;

export const StyledTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${theme.device.laptop} {
    margin-top: 15px;
    flex-direction: row;
  }
`;

export const StyledTime = styled.span`
  margin-top: 5px;

  @media ${theme.device.laptop} {
    font-size: ${theme.typography.fontSize.s};
    font-weight: ${theme.typography.fontWeight.medium};

    margin-top: 0;

    &:first-of-type::after {
      content: ' / ';
      margin-right: 3px;
    }
  }
`;
