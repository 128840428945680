import styled from 'styled-components';
import { Orientation, Size } from 'types/common';
import { theme } from 'src/styles/theme';

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  &.${Orientation.Horizontal} {
    &.${Size.M} {
      width: 100%;
      margin: 50px 0;

      .big-checkbox {
        flex: 1 0 0%;
        min-width: 24%;
        align-items: center;

        @media ${theme.device.laptop} {
          min-width: 100%;
        }
      }

      @media ${theme.device.laptop} {
        margin: 30px 0;
      }
    }

    &.${Size.S} {
      margin: 30px 0;
      gap: 10px 25px;
    }

    &.single {
      width: 80%;
      .big-checkbox {
        min-width: 40%;
      }
    }
  }

  &.${Orientation.Vertical} {
    flex-direction: column;
    align-items: start;

    &.${Size.S} {
      gap: 10px;

      @media ${theme.device.laptop} {
        gap: 20px;
      }
    }
  }

  @media ${theme.device.laptop} {
    &.${Orientation.Horizontal} {
      &.single {
        width: 100%;
        flex-direction: column;
      }
    }
  }
`;
