import styled from 'styled-components';
import { theme } from 'src/styles/theme';

export const StyledNavLink = styled.a`
  color: #22252a;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent !important;
  transition: color 0.6s ease-in-out;

  position: relative;
  z-index: 10;

  &:hover {
    color: #22252a;
  }

  @media ${theme.device.laptop} {
    color: ${theme.colors.white};
    font-size: 30px;
    font-weight: ${theme.typography.fontWeight.semiBold};

    &:hover {
      color: ${theme.colors.white};
    }
  }
`;

export const StyledLinkDecorator = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0px;
  left: -10px;
  background-color: ${theme.colors.primary};
  transform: scaleX(0) scaleY(0.5);
  transform-origin: left bottom;
  transition: transform 0.6s ease;

  @media ${theme.device.laptop} {
    right: 78%;
    left: 0;
  }
`;

export const StyledContactLine = styled.div`
  display: none;
`;

export const StyledLinkWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  transition: transform 0.6s ease;

  &:hover {
    ${StyledLinkDecorator} {
      transform: scaleX(0.7) scaleY(0.5);
      transition: transform 0.6s ease;
    }
  }

  &.active {
    ${StyledLinkDecorator} {
      transform: scaleX(1) scaleY(1);
      transition: transform 0.6s ease;
    }
  }

  &.contact {
    margin-left: 15px;

    ${StyledNavLink} {
      font-size: 18px;
      font-weight: 800;
    }

    ${StyledContactLine} {
      display: block;
      width: 28px;
      height: 2px;
      background-color: #22252a;
      margin-left: 12px;
    }

    @media ${theme.device.laptop} {
      margin: 30px 0 0;

      ${StyledNavLink} {
        font-size: ${theme.typography.fontSize.l};
        font-weight: ${theme.typography.fontWeight.extraBold};
      }

      ${StyledContactLine} {
        background-color: ${theme.colors.white};
      }
    }
  }

  @media ${theme.device.laptop} {
    margin-top: 20px;

    &:hover {
      ${StyledLinkDecorator} {
        display: none;
      }
    }
  }
`;
