import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import { Button, Container } from 'atoms';

export const StyledContainer = styled.div`
  display: flex;
  min-height: 600px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 70px;

  @media ${theme.device.laptop} {
    flex-direction: column;
    margin: 0;
  }
`;

export const StyledContentWrapper = styled.div`
  @media ${theme.device.laptop} {
    padding-top: 60px;
  }
`;

export const StyledSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${theme.colors.backgroundYellow};

  @media ${theme.device.laptop} {
    padding-bottom: 80px;
  }
`;

export const StyledTitle = styled.h3`
  font-size: ${theme.typography.fontSize.s};
  font-weight: ${theme.typography.fontWeight.semiBold};
  color: ${theme.colors.dark};
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 25px;

  @media ${theme.device.laptop} {
    font-size: ${theme.typography.fontSize.xs};
    line-height: 16px;

    margin-bottom: 30px;
  }
`;

export const StyledTime = styled.span`
  font-size: ${theme.typography.fontSize.xxl};
  font-weight: ${theme.typography.fontWeight.black};
  color: ${theme.colors.dark};
  line-height: 74px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;

  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }

  @media ${theme.device.laptop} {
    font-size: ${theme.typography.fontSize.mobXl};
    line-height: 44px;

    margin-bottom: 10px;
  }
`;

export const StyledMonth = styled.span`
  font-size: ${theme.typography.fontSize.s};
  color: ${theme.colors.dark};
  line-height: 24px;
  margin-top: 30px;
`;

export const StyledPlatfromList = styled.ul`
  display: flex;
  gap: 40px;
  margin-top: 40px;

  @media ${theme.device.laptop} {
    flex-direction: column;
    margin-top: 24px;
  }
`;

export const StyledButtonContainer = styled.div`
  @media ${theme.device.laptop} {
    width: 100%;
    padding: 30px 0;
    background-color: ${theme.colors.backgroundYellow};
  }
`;

export const StyledInnerButtonContainer = styled.div`
  @media ${theme.device.laptop} {
    width: ${theme.width.normal};
    max-width: ${theme.width.mobileMax};
    margin: auto;
  }
`;

export const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${theme.colors.backgroundGray};

  @media ${theme.device.laptop} {
    margin: auto;
    align-items: start;
    background-color: ${theme.colors.white};
  }
`;

export const StyledFormTitle = styled.h2`
  font-size: ${theme.typography.fontSize.l};
  font-weight: ${theme.typography.fontWeight.extraBold};
  line-height: 40px;
  color: ${theme.colors.dark};
  width: 100%;
  text-align: center;
  margin-top: auto;
  white-space: break-spaces;

  @media ${theme.device.laptop} {
    font-size: ${theme.typography.fontSize.r};
    line-height: 27px;
    text-align: left;
  }
`;

export const StyledDescription = styled.p`
  font-size: ${theme.typography.fontSize.s};
  line-height: 24px;
  color: ${theme.colors.secondary};
  text-align: center;
  margin-top: 15px;

  @media ${theme.device.laptop} {
    margin-top: 10px;
    text-align: left;
  }
`;

export const StyledFormWrapper = styled(Container)`
  position: relative;
  padding: 50px 0;
`;

export const StyledFooter = styled.div`
  width: 100%;
  font-size: ${theme.typography.fontSize.s};
  line-height: 24px;
  color: ${theme.colors.dark};
  text-align: center;
  background-color: ${theme.colors.lightGray};
  margin-top: auto;
  padding: 16px 50px;

  a {
    color: ${theme.colors.dark};
    font-weight: ${theme.typography.fontWeight.semiBold};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media ${theme.device.laptop} {
    padding: 30px;
    background-color: ${theme.colors.backgroundGray};
    text-align: left;

    a {
      text-decoration: underline;
    }
  }
`;

export const StyledButton = styled(Button)`
  margin: 40px auto 0;
  width: 70%;
  text-decoration: none;
  text-align: center;

  @media ${theme.device.laptop} {
    position: relative;
    width: 100%;
    transform: translate(0, 0);
    left: 0;
    bottom: 0;
  }
`;

export const StyledText = styled.p`
  @media ${theme.device.laptop} {
    max-width: ${theme.width.mobileMax};
    margin: auto;
    &:first-child {
      margin-bottom: 10px;
    }
  }
`;

export const StyledErrorMessage = styled.span`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: ${theme.colors.error};
`;
