import React, { HTMLAttributes } from 'react';
import { URLs } from 'src/constants';
import { StyledContainer, StyledLink, StyledContent } from './ContactUs.styles';

const ContactUs: React.FC<HTMLAttributes<HTMLElement>> = props => (
  <StyledContainer {...props}>
    <StyledContent>
      Need help? Contact us today to get free detailed estimate and cosultation for your product
      <StyledLink href={URLs.CONTACT} target="_blank" rel="noreferrer">
        contact us
      </StyledLink>
    </StyledContent>
  </StyledContainer>
);

export default ContactUs;
