import { PlatformType, PlatformsEstimate, EstimateType } from 'types/store';

export const sumQuestionEstimation = (estimates: PlatformsEstimate[], platformType: PlatformType) =>
  estimates.reduce(
    (total: EstimateType, platformEstimate: PlatformsEstimate) => {
      const estimate = platformEstimate[platformType];
      return [total[0] + estimate[0], total[1] + estimate[1]] as EstimateType;
    },
    [0, 0],
  );
