import React, { useState, useCallback, useEffect } from 'react';

import { URLs } from 'src/constants';
import { Checkbox, LoadingButton } from 'atoms';
import { Input } from 'molecules';

import { StyledForm, StyledCheckboxContainer } from './Form.styles';
import { validateName, validateEmail } from './Form.utils';
import { useInputWithValidation } from './Form.hook';
import { FormProps } from './Form.types';

const Form: React.FC<FormProps> = ({ onSubmit, loading, ...props }) => {
  const [isAgree, setAgree] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [name, setName, isValidName] = useInputWithValidation(validateName);
  const [showEmailError, setShowEmailError] = useState(false);
  const [email, setEmail, isValidEmail] = useInputWithValidation(validateEmail);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!isValidName) {
        setShowNameError(true);
      }
      if (!isValidEmail) {
        setShowEmailError(true);
      }

      if (onSubmit && isValidEmail && isValidName) {
        onSubmit({ email, name });
      }
    },
    [onSubmit, isValidEmail, isValidName, setShowNameError, setShowEmailError, email, name],
  );

  useEffect(() => {
    setShowEmailError(false);
  }, [email]);

  useEffect(() => {
    setShowNameError(false);
  }, [name]);

  const disabledSumbit = !isAgree || !email.length || !name.length;

  return (
    <StyledForm onSubmit={handleSubmit} {...props} noValidate>
      <Input
        label="Name"
        placeholder="Jon Snow"
        type="text"
        value={name}
        onChange={setName}
        error={showNameError ? 'Please enter your name!' : ''}
      />
      <Input
        label="Email"
        placeholder="example@uptech.team"
        type="email"
        value={email}
        onChange={setEmail}
        error={showEmailError ? 'Please enter valid email!' : ''}
      />
      <StyledCheckboxContainer>
        <Checkbox
          value="privacy-policy"
          id="privacy-policy"
          checked={isAgree}
          onChange={() => setAgree(prev => !prev)}
        />
        <label htmlFor="privacy-policy">
          I agree to our{' '}
          <a href={URLs.PRIVACY_POLICY} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
        </label>
      </StyledCheckboxContainer>
      <LoadingButton loading={loading} disabled={disabledSumbit}>
        get pdf
      </LoadingButton>
    </StyledForm>
  );
};

export default Form;
