import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import { Button } from 'atoms';

export const StyledButton = styled(Button)`
  margin: 32px auto 0;
  width: 100%;

  @media ${theme.device.laptop} {
    position: relative;
    top: 0;
    left: 0;
    transform: translateX(0);
    margin: 44px auto 0;
  }
`;
