import styled from 'styled-components';
import { theme, customScroll } from 'src/styles/theme';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${theme.device.laptop} {
    height: 100%;
    padding-top: 70px;
  }
`;

export const StyledHeader = styled.span`
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.r};
  color: ${theme.colors.dark};
  line-height: 30px;
`;

export const StyledCategoryList = styled.ul`
  max-height: 310px;
  overflow-y: auto;
  ${customScroll}

  @media ${theme.device.laptop} {
    max-height: none;
    overflow-y: unset;
  }
`;

export const StyledCategoryItem = styled.li`
  font-weight: ${theme.typography.fontWeight.semiBold};
  font-size: ${theme.typography.fontSize.xs};
  color: ${theme.colors.dark};
  line-height: 16px;
  text-transform: uppercase;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

export const StyledQuestionList = styled.ul`
  margin-top: 10px;
`;

export const StyledQuestionItem = styled.li`
  font-weight: ${theme.typography.fontWeight.regular};
  font-size: ${theme.typography.fontSize.s};
  color: ${theme.colors.secondary};
  line-height: 24px;
  text-transform: none;
  margin-bottom: 5px;
  padding-right: 20px;

  display: flex;
  justify-content: space-between;
`;

export const StyledTotal = styled.div`
  font-weight: ${theme.typography.fontWeight.semiBold};
  font-size: ${theme.typography.fontSize.xs};
  color: ${theme.colors.dark};
  line-height: 16px;
  text-transform: uppercase;
  padding-top: 30px;

  @media ${theme.device.laptop} {
    padding-bottom: 30px;
  }
`;

export const StyledSubTotal = styled(StyledTotal)`
  margin-top: 14px;
  border-top: 1px solid ${theme.colors.lightGray};
  background: url("data:image/svg+xml,%3Csvg width='43' height='26' viewBox='0 0 43 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M30 0.428467L0 25.7281H30V0.428467Z' fill='%2300BB86'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M41.5859 2.08472L20.001 20.2595L21.2281 21.2866L42.813 3.1118L41.5859 2.08472Z' fill='%2322252A'/%3E%3C/svg%3E")
    no-repeat right bottom;

  @media ${theme.device.laptop} {
    background: none;
  }
`;

export const StyledContentWrapper = styled.div`
  @media ${theme.device.laptop} {
    width: ${theme.width.normal};
    max-width: ${theme.width.mobileMax};
    margin: 0 auto;
  }
`;
