import React from 'react';
import classnames from 'classnames';
import CountUp from 'react-countup';

import { Size } from 'types/common';

import { StyledEstimate } from './Estimate.styles';
import { EstimateProps } from './Estimate.types';

const Estimate: React.FC<EstimateProps> = ({ estimate, size = Size.S, className }) => {
  const classNames = classnames(className, size);

  return (
    <StyledEstimate className={classNames}>
      min <CountUp preserveValue end={estimate[0]} duration={0.5} />h / max{' '}
      <CountUp preserveValue end={estimate[1]} duration={0.5} />h
    </StyledEstimate>
  );
};

export default Estimate;
