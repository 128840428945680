import React, { HTMLAttributes } from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as PaperIcon } from 'images/letter/paper.svg';
import { ReactComponent as ConvertIcon } from 'images/letter/convert.svg';
import { ReactComponent as HeaderIcon } from 'images/letter/header.svg';
import { ReactComponent as ConfettiIcon } from 'images/letter/confetti.svg';
import { theme } from 'src/styles/theme';

const paperAnimation = keyframes`
 0% { transform: translate(-50%, -30%); }
 100% { transform: translate(-50%, -50%); }
`;

const confettiAnimation = keyframes`
  0% {
    transform: translate(-50%, -30%) scale(0, 0);
    opacity: 0;
  }
  80% {
    transform: translate(-50%, -100%) scale(1.3, 1.3);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -90%) scale(1, 1);
    opacity: 0.8;
  }
`;

const StyledPaper = styled(PaperIcon)`
  animation-name: ${paperAnimation};
  animation-duration: 1s;
  animation-iteration-count: 1;
`;

const StyledCofetti = styled(ConfettiIcon)`
  animation-name: ${confettiAnimation};
  animation-duration: 1.2s;
  animation-iteration-count: 1;
`;

const StyledContainer = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${StyledCofetti} {
    transform: translate(-50%, -90%);
  }

  @media ${theme.device.laptop} {
    min-height: 285px;
    width: 100%;
    height: 100%;
    overflow: hidden;

    svg {
      top: 75%;
      width: 90%;
      height: 50%;
    }
  }
`;

const Letter: React.FC<HTMLAttributes<HTMLElement>> = props => {
  return (
    <StyledContainer {...props}>
      <StyledCofetti />
      <HeaderIcon />
      <StyledPaper />
      <ConvertIcon />
    </StyledContainer>
  );
};

export default Letter;
