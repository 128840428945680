import { keyframes } from 'styled-components';

export const animations = {
  pathTopToCross: keyframes`
        0% {
            transform: translate3d(0, 0, 0) scaleX(0.5882);
            transform-origin: right;
            opacity: 1;
        }
        33% {
            transform: translate3d(0, 0, 0) scaleX(1);
            transform-origin: right;
            opacity: 1;
        }
        65% {
            transform: translate3d(0, 7px, 0) scaleX(1);
            transform-origin: right;
            opacity: 1;
        }
        66% {
            transform: translate3d(0, 7px, 0) scaleX(1);
            transform-origin: right;
            opacity: 0;
        }
        
        100% {
            transform: rotate(-45deg) translate3d(0, 7px, 0) scaleX(1);
            transform-origin: 70% 30%;
            opacity: 0;
        }
  `,
  pathMiddleToCross: keyframes`
        0% {
            opacity: 1;
        }
        65% {
            opacity: 1;
        }
        66% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
  `,
  pathBottomToCross: keyframes`
        0% {
            transform: translate3d(0, 0, 0) scaleX(1.4);
            transform-origin: right;
            opacity: 1;
        }
        33% {
            transform: translate3d(0, 0, 0) scaleX(1);
            transform-origin: right;
            opacity: 1;
        }
        66% {
            transform: translate3d(0, -7px, 0) scaleX(1);
            transform-origin: right;
            opacity: 1;
        }
        67% {
            transform: translate3d(0, -7px, 0) scaleX(1);
            transform-origin: right;
            opacity: 0;
        }
        100% {
            transform: rotate(45deg) translate3d(0, -7px, 0) scaleX(1);
            transform-origin: 71% 64%;
            opacity: 0;
        }
  `,
  crossPartLeftToCross: keyframes`
        0% {
            transform: rotate(0);
            opacity: 0;
        }
        66% {
            opacity: 0;
        }
        67% {
            transform: rotate(0);
            opacity: 1;
        }
        100% {
            transform: rotate(45deg);
            opacity: 1;
        }
  `,
  crossPartRightToCross: keyframes`
        0% {
            transform: rotate(0);
            opacity: 0;
        }
        66% {
            opacity: 0;
        }
        67% {
            transform: rotate(0);
            opacity: 1;
        }
        100% {
            transform: rotate(-45deg);
            opacity: 1;
        }
  `,
  pathTopFromCross: keyframes`
        0% {
            transform: rotate(-45deg) translate3d(0, 7px, 0) scaleX(1);
            transform-origin: 70% 30%;
            opacity: 0;
        }
        33% {
            transform: translate3d(0, 7px, 0) scaleX(1);
            transform-origin: right;
            opacity: 0;
        }
        34% {
            transform: translate3d(0, 7px, 0) scaleX(1);
            transform-origin: right;
            opacity: 1;
        }
        66% {
            transform: translate3d(0, 0, 0) scaleX(1);
            transform-origin: right;
            opacity: 1;
        }

        100% {
            transform: translate3d(0, 0, 0) scaleX(0.5882);
            transform-origin: right;
            opacity: 1;
        }
  `,
  pathMiddleFromCross: keyframes`
        0% {
            opacity: 0;
        }
        33% {
            opacity: 0;
        }
        34% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }
  `,
  pathBottomFromCross: keyframes`
        0% {
            transform: rotate(45deg) translate3d(0, -7px, 0) scaleX(1);
            transform-origin: 71% 64%;
            opacity: 0;
        }
        33% {
            transform: translate3d(0, -7px, 0) scaleX(1);
            transform-origin: right;
            opacity: 0;
        }
        34% {
            transform: translate3d(0, -7px, 0) scaleX(1);
            transform-origin: right;
            opacity: 1;
        }
        66% {
            transform: translate3d(0, 0, 0) scaleX(1);
            transform-origin: right;
            opacity: 1;
        }

        100% {
            transform: translate3d(0, 0, 0) scaleX(1.4);
            transform-origin: right;
            opacity: 1;
        }
  `,
  crossPartLeftFromCross: keyframes`
        0% {
            transform: rotate(45deg);
            opacity: 1;
            transform-origin: 66% 50%;
        }
        33% {
            transform: rotate(0);
            opacity: 1;
            transform-origin: 66% 50%;
        }
        34% {
            transform: rotate(0);
            opacity: 0;
        }
        100% {
            transform: rotate(0);
            opacity: 0;
        }
  `,
  crossPartRightFromCross: keyframes`
        0% {
            transform: rotate(-45deg);
            opacity: 1;
            transform-origin: 66% 50%;
        }
        33% {
            transform: rotate(0);
            opacity: 1;
            transform-origin: 66% 50%;
        }
        34% {
            transform: rotate(0);
            opacity: 0;
        }
        100% {
            transform: rotate(0);
            opacity: 0;
        }
  `,
};
