import { headerStyles } from 'src/constants';
import { useEffect, useState } from 'react';
import { debounce } from 'utils';
import { getIsScrollInFooter } from './Layout.utils';

const containerClassNames = {
  IN_FOOTER: 'in-footer',
  OUT_FOOTER: 'out-footer',
};

export const useScrollPosition = (): [string] => {
  const [containerClassName, setContainerClassName] = useState(containerClassNames.OUT_FOOTER);

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (getIsScrollInFooter()) {
        setContainerClassName(containerClassNames.IN_FOOTER);
      } else {
        setContainerClassName(containerClassNames.OUT_FOOTER);
      }
    }, 150);

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [containerClassName];
};

export const useChangeHeaderColor = (routeKey: string): [string] => {
  const defaultColor = '#fff';
  const [bgColor, setBgColor] = useState(defaultColor);

  useEffect(() => {
    const { bgMain, changeHeight, bgOnChange } = headerStyles[routeKey] || {};

    if (!bgMain) {
      setBgColor(defaultColor);
    } else {
      setBgColor(bgMain);
    }

    const handleScroll = () => {
      if (!headerStyles[routeKey]) {
        return;
      }

      if (window.scrollY > changeHeight) {
        setBgColor(bgOnChange);
      } else {
        setBgColor(bgMain);
      }
    };
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [routeKey]);

  return [bgColor];
};
