import styled from 'styled-components';
import { theme } from 'src/styles/theme';

const { colors, typography } = theme;

const Description = styled.p`
  color: ${colors.secondary};
  font-size: ${typography.fontSize.s};
  font-weight: ${typography.fontWeight.regular};
  text-align: center;
  width: 100%;
  margin-top: 26px;
`;

export default Description;
