import React from 'react';

export type Link = {
  href: string;
  title?: string;
  className?: string;
  isActive?: boolean;
};

export type SocialLink = Link & {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export type NavbarWithLabel = {
  label: string;
  links: Link[];
};

export enum Orientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export type SelectionOption = {
  value: string;
  id?: string;
  label?: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  checked?: boolean;
  disabled?: boolean;
};

export enum Size {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
}

export enum PopUpEnum {
  questions = 'questions',
  summary = 'summary',
}

export interface HeaderStyles {
  bgMain: string;
  bgOnChange: string;
  changeHeight: number;
}
