import React, { HTMLAttributes } from 'react';
import { StyledContainer, StyledLoader, StyledParagraph } from './Preloader.styles';

const Preloader: React.FC<HTMLAttributes<HTMLDivElement>> = props => {
  return (
    <StyledContainer {...props}>
      <StyledLoader />
      <StyledParagraph>
        We are loading a list of features for your app.
        <br />
        It&apos;s almost ready!
      </StyledParagraph>
    </StyledContainer>
  );
};

export default Preloader;
