import styled from 'styled-components';
import { theme } from 'src/styles/theme';

export const StyledContainer = styled.div`
  display: none;
  width: 100%;
  background-color: ${theme.colors.backgroundGray};

  @media ${theme.device.laptop} {
    display: block;
  }
`;

export const StyledTotal = styled.div`
  font-weight: ${theme.typography.fontWeight.semiBold};
  font-size: ${theme.typography.fontSize.xs};
  color: ${theme.colors.dark};
  line-height: 16px;
  text-transform: uppercase;
  padding-top: 50px;
`;

export const StyledButton = styled.button`
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.s};
  line-height: 18px;
  color: ${theme.colors.dark};
  text-transform: uppercase;
  text-decoration: none;
  background: transparent;

  display: block;
  width: 100%;
  max-width: 260px;
  border: 4px solid ${theme.colors.dark};
  text-align: center;
  padding: 15px;
  margin: 30px auto;
  opacity: 0.8;
  transition: opacity 0.2s;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;
