export const getIsScrollInFooter = () => {
  const footerElement = document.getElementById('footer');
  const footerRect = footerElement?.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();
  const scrollPosition = window.scrollY + bodyRect.bottom;

  if (!footerElement || !footerRect) {
    return;
  }

  return scrollPosition > footerRect.top;
};
