import React from 'react';
import classNames from 'classnames';

import { CheckboxProps } from './Checkbox.types';
import { StyledContainer, StyledInput, StyledLabel, StyledIndicator } from './Checkbox.styles';

const Checkbox: React.FC<CheckboxProps> = ({
  className,
  label,
  checked = false,
  disabled = false,
  radial = false,
  ...props
}) => {
  const classes = classNames(className, { checked, disabled, radial });

  return (
    <StyledContainer className={classes}>
      <StyledInput type="checkbox" checked={checked} disabled={disabled} {...props} />
      <StyledIndicator />
      {label && <StyledLabel>{label}</StyledLabel>}
    </StyledContainer>
  );
};

export default Checkbox;
