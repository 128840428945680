import styled from 'styled-components';
import { theme, customScroll } from 'src/styles/theme';

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  max-height: 242px;
  overflow-y: auto;

  @media ${theme.device.laptop} {
    flex-direction: column;
    max-width: none;
    max-height: none;
    overflow-y: unset;
  }

  ${customScroll}
`;

export const StyledTitle = styled.p`
  color: ${theme.colors.dark};
  font-weight: ${theme.typography.fontWeight.semiBold};
  line-height: 34px;
  margin-bottom: 10px;
`;

export const StyledOptionList = styled.div`
  width: 100%;

  @media ${theme.device.laptop} {
    margin-top: 40px;
  }
`;
