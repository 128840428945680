import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import { ReactComponent as Logo } from 'images/logo-white.svg';
import { FooterTitle } from 'atoms';

export const StyledLogo = styled(Logo)`
  margin-bottom: ${theme.spacing(1)};

  @media ${theme.device.laptop} {
    grid-area: logo-column;
  }
`;

export const StyledFooterWrapper = styled.footer`
  background: ${theme.colors.footer.background};
  width: 100%;
  padding-top: ${theme.spacing(15)};
  margin-top: auto;

  grid-area: footer;
`;

export const StyledDivider = styled.div`
  width: 100%;
  background-color: hsla(0, 0%, 100%, 0.1);
  height: 1px;
`;

// CSS below copied from https//:uptech.team/
export const StyledUpContainer = styled.div`
  width: ${theme.width.normal};
  margin: auto;
  padding-bottom: 36px;
  display: grid;
  justify-content: space-between;
  justify-items: start;
  align-items: start;
  align-content: stretch;
  flex: 1;
  grid-auto-columns: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 16px;
  grid-template-columns: 25% 16% 16% 18% 21%;
  grid-template-rows: auto;

  @media ${theme.device.laptop} {
    max-width: ${theme.width.mobileMax};
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    row-gap: 40px;
    justify-content: start;
    grid-template-areas:
      'logo-column .'
      'company-column insights-column'
      'contact-column .'
      'icons-column icons-column';

    .footer-heading {
      display: none;
    }
  }
`;

export const StyledContainerDown = styled.div`
  width: ${theme.width.normal};
  margin: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-row-gap: 16px;
  justify-content: space-between;
  justify-items: start;
  align-items: start;
  flex: 1;
  grid-auto-flow: row;
  grid-column-gap: 0;
  grid-template-areas: '. Area';
  grid-template-columns: 60% 40%;
  grid-template-rows: auto;

  @media ${theme.device.laptop} {
    max-width: ${theme.width.mobileMax};
    display: flex;
    flex-direction: column-reverse;

    ${FooterTitle} {
      margin: 7px auto auto;
    }
  }
`;
