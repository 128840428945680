import { theme } from 'src/styles/theme';
import styled from 'styled-components';

export const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.dark};
  font-size: ${theme.typography.fontSize.s};
  line-height: 24px;
  padding: 50px 0;
  background-color: ${theme.colors.backgroundGray};

  p {
    margin-top: 20px;
  }

  @media ${theme.device.laptop} {
    padding: 40px 0;
  }
`;

export const StyledTitle = styled.h3`
  font-size: ${theme.typography.fontSize.r};
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 30px;
`;
