import React from 'react';
import classNames from 'classnames';

import { BigCheckboxProps } from './BigCheckbox.types';
import { StyledContainer, StyledInput, StyledLabel } from './BigCheckbox.styles';

const BigCheckbox: React.FC<BigCheckboxProps> = ({
  className,
  label,
  Icon,
  checked = false,
  disabled = false,
  ...props
}) => {
  const classes = classNames(className, { checked, disabled });

  return (
    <StyledContainer className={classes}>
      {Icon && <Icon />}
      <StyledInput type="checkbox" checked={checked} {...props} />
      {label && <StyledLabel>{label}</StyledLabel>}
    </StyledContainer>
  );
};

export default BigCheckbox;
