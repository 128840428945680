import React, { HTMLAttributes, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useGlobalStore } from 'store/GlobalStore';
import { AppRoutes, MAIN_URL, URLs } from 'src/constants';

import { Title, Button, Container } from 'atoms';
import { SelectionList } from 'molecules';
import { StyledContainer, StyledNotice, StyledWrapper } from './SelectDomain.styles';
import { useScrollPosition } from 'templates/Layout/Layout.hook';

const SelectDomain: React.FC<HTMLAttributes<HTMLElement>> = () => {
  const {
    calculatorStore: { domainOptions, setDomain, domain },
    setGaClientId,
  } = useGlobalStore();
  const navigation = useNavigate();
  const [containerClassName] = useScrollPosition();

  const handleOnChange = useCallback(
    values => {
      if (values.length === 1) {
        setDomain(values[0]);
        return;
      }
      setDomain('');
    },
    [setDomain],
  );

  const canMoveNext = Boolean(domain);

  const handleMoveNext = useCallback(() => {
    navigation(AppRoutes.SELECT_PLATFORMS);
  }, [navigation]);

  useEffect(() => {
    // set GA client Id from main site to globalStore
    const domains = ['https://uptech-site.webflow.io', MAIN_URL];

    window.addEventListener('message', event => {
      if (domains.includes(event.origin)) {
        const clientGaId = event.data;

        setGaClientId(clientGaId);
      }
    });
  }, []);

  return (
    <>
      <Container className={`mainWrapper ${containerClassName}`}>
        <StyledWrapper>
          <Title>Choose your business</Title>
          <SelectionList
            style={{ marginTop: '40px' }}
            options={domainOptions}
            preSelectedOptions={domain ? [domain] : []}
            handleOnChange={handleOnChange}
          />
          <StyledContainer>
            <StyledNotice>
              Don’t see needed category?{' '}
              <a href={URLs.CONTACT} target="_blank" rel="noreferrer">
                Contact us
              </a>{' '}
              today to get free detailed estimate and consultation for your app
            </StyledNotice>
            {canMoveNext && (
              <Button id="nextButton" onClick={handleMoveNext}>
                Next
              </Button>
            )}
          </StyledContainer>
        </StyledWrapper>
      </Container>
    </>
  );
};

export default observer(SelectDomain);
