import React from 'react';
import { StyledLoader } from '../Preloader/Preloader.styles';
import { StyledButton } from './LoadingButton.styles';
import { LoadingButtonProps } from './LoadingButton.types';

const LoadingButton: React.FC<LoadingButtonProps> = ({ loading, children, disabled, ...props }) => {
  return (
    <StyledButton disabled={loading || disabled} {...props}>
      {loading ? <StyledLoader style={{ width: '18px', height: '18px' }} /> : children}
    </StyledButton>
  );
};

export default LoadingButton;
