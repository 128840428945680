import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import 'normalize.css';
import 'src/styles/global.css';
import { SelectDomain, Calculator, SelectPlatform, Summary } from 'pages';
import GlobalStore, { StoreContext } from 'store/GlobalStore';
import { AppRoutes } from 'src/constants';

function App() {
  const store = new GlobalStore();

  // if you need navigation and footer, you can wrap <Routes /> in <Layout />
  return (
    <StoreContext.Provider value={store}>
      <BrowserRouter>
        <Routes>
          <Route path={AppRoutes.SELECT_DOMAIN} element={<SelectDomain />} />
          <Route path={AppRoutes.SELECT_PLATFORMS} element={<SelectPlatform />} />
          <Route path={AppRoutes.CALCULATOR} element={<Calculator />} />
          <Route path={AppRoutes.SUMMARY} element={<Summary />} />
          <Route path="*" element={<Navigate to={AppRoutes.SELECT_DOMAIN} replace />} />
        </Routes>
      </BrowserRouter>
    </StoreContext.Provider>
  );
}

export default App;
