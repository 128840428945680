import { theme } from 'src/styles/theme';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  margin: 20px 0;
`;

export const StyledTab = styled.button`
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;

  font-weight: ${theme.typography.fontWeight.semiBold};
  font-size: ${theme.typography.fontSize.xs};
  color: ${theme.colors.secondary};
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid ${theme.colors.lightGray};
  flex-grow: 1;
  padding-bottom: 15px;
  transition: all 0.2s;

  &.active,
  &.active:hover {
    color: ${theme.colors.dark};
    border-bottom: 4px solid ${theme.colors.primary};
    padding-bottom: 13px;
  }

  &:hover {
    border-bottom: 2px solid ${theme.colors.disable};
  }
`;

export const StyledSingleTab = styled.div`
  font-weight: ${theme.typography.fontWeight.semiBold};
  font-size: ${theme.typography.fontSize.xs};
  line-height: 16px;
  text-transform: uppercase;

  @media ${theme.device.laptop} {
    width: ${theme.width.normal};
    max-width: ${theme.width.mobileMax};
    margin: 20px auto;
  }
`;

export const StyledTabsContainer = styled.div`
  display: flex;

  @media ${theme.device.laptop} {
    max-width: ${theme.width.mobileMax};
    margin: auto;
  }
`;
