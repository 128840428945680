import React, { useMemo } from 'react';
import { Size, Orientation } from 'types/common';

import { SelectionList } from 'molecules';

import { StyledContainer, StyledTitle, StyledOptionList } from './OptionList.styles';
import { OptionListProps } from './OptionList.types';

const OptionList: React.FC<OptionListProps> = ({ options, selectedOptions, setOptions }) => {
  const mustHaveList = useMemo(() => options.filter(option => option.disabled), [options]);
  const optionalList = useMemo(() => options.filter(option => !option.disabled), [options]);

  return (
    <StyledContainer>
      {!!mustHaveList.length && (
        <div style={{ marginRight: '90px', width: '100%' }}>
          <StyledTitle>Must have</StyledTitle>
          <SelectionList
            options={mustHaveList}
            preSelectedOptions={selectedOptions}
            size={Size.S}
            orientation={Orientation.Vertical}
            multiselect
          />
        </div>
      )}
      {!!optionalList.length && (
        <StyledOptionList>
          <StyledTitle>Optional</StyledTitle>
          <SelectionList
            options={optionalList}
            preSelectedOptions={selectedOptions}
            size={Size.S}
            orientation={Orientation.Vertical}
            handleOnChange={setOptions}
            multiselect
          />
        </StyledOptionList>
      )}
    </StyledContainer>
  );
};

export default OptionList;
