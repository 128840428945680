/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createContext, useContext } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { MAIN_URL, URLs, templateId } from 'src/constants';
import { PopUpEnum } from 'types/common';

import CalculatorStore from '../CalculatorStore';

class GlobalStore {
  constructor() {
    makeObservable(this, {
      isLoading: observable,
      showPreloader: action,
      hidePreloader: action,
      submitLoading: observable,
      setSubmitLoading: action,
      //success send report
      isSuccess: observable,
      setIsSuccess: action,
      //errors
      errorMessage: observable,
      setError: action,
      //popup
      popUp: observable,
      setPopUpAction: action,
      //GA
      gaClientId: observable,
    });

    this.calculatorStore = new CalculatorStore();
    this.isLoading = false;
    this.submitLoading = false;
    this.errorMessage = '';
    this.isSuccess = false;
    this.popUp = null;
    this.gaClientId = '';
  }

  calculatorStore: CalculatorStore;
  isLoading: boolean;
  submitLoading: boolean;
  errorMessage: string;
  isSuccess: boolean;
  popUp: PopUpEnum | null;
  gaClientId: string;

  hidePreloader = () => {
    this.isLoading = false;
  };

  showPreloader = () => {
    this.isLoading = true;
    setTimeout(this.hidePreloader, 1500);
  };

  setError = (value: string) => {
    this.errorMessage = value;
  };

  setIsSuccess = (value: boolean) => {
    this.isSuccess = value;
  };

  setPopUpAction = (value: PopUpEnum | null) => {
    this.popUp = value;
  };

  setSubmitLoading = (flag: boolean) => {
    this.submitLoading = flag;
  };

  sendGTagAnalytics = ({ name, email }: { name: string; email: string }) => {
    const GTAG_API_URL = 'https://hook.eu1.make.com/k1713qnwnc47vp9ivt7d5rtjct8ulrxh';

    // @ts-ignore
    fetch(GTAG_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: '37d0598f-1347-4e45-803f-45a7a7f179b7',
      },
      body: JSON.stringify({
        name,
        email,
        tags: 'calculator',
        ga_client_id: this.gaClientId,
        form_name: 'Calculator form',
      }),
    }).then(res => console.log(res.status, 'ok with clientId:', this.gaClientId));
  };

  sendEventToParentWeb = (message: { status: string; event: string }) => {
    console.log('post message to parent', message, `${MAIN_URL}`);
    window.parent.postMessage(message, `${MAIN_URL}`);
  };

  sendReport = async (data: object) => {
    this.setSubmitLoading(true);
    fetch(URLs.SEND_REPORTS, {
      method: 'POST',
      body: JSON.stringify({ templateId, ...data }),
    })
      .then(response => {
        if (!response.ok) {
          this.sendEventToParentWeb({
            status: 'failed',
            event: 'formSubmitted',
          });
          this.setError(`${response.statusText} status: ${response.status}`);
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
      })
      .then(() => {
        // ADD gtag ANALYTICS
        this.sendEventToParentWeb({
          status: 'success',
          event: 'formSubmitted',
        });
        this.sendGTagAnalytics(data as { name: string; email: string });
        console.log('ok');

        this.setSubmitLoading(false);
        this.setIsSuccess(true);
      })
      .catch(error => {
        this.sendEventToParentWeb({
          status: 'failed',
          event: 'formSubmitted',
        });
        this.setSubmitLoading(false);
        this.setError(error.message);
      });
  };

  setGaClientId = (clientId: string) => {
    this.gaClientId = clientId;
  };
}

export const StoreContext = createContext<GlobalStore | null>(null);

export const useGlobalStore = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('You have forgot to use StoreProvider, shame on you. (c) Michel Weststrate');
  }
  return store;
};

export default GlobalStore;
