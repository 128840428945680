import { theme } from 'src/styles/theme';
import styled from 'styled-components';
import { FooterTitle, FooterLink } from 'atoms';

export const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 100%;
  background-color: ${theme.colors.footer.background};
  transform: translate3d(0, 0, 0);
  transition: transform 0.45s ease-in-out;
  padding-top: 87px;
  z-index: ${theme.index.top};

  .menu-open & {
    transform: translate3d(-100%, 0, 0) !important;
  }
`;

export const StyledOutherContainer = styled.div`
  overflow: scroll;
  z-index: 0;
  transform: translate3d(0, 0, 0);
  max-height: 100%;
  will-change: transform;
  scroll-behavior: smooth;
`;

export const StyledSocialLinks = styled.div`
  margin: 40px 0;
  display: grid;
  grid-template-rows: repeat(3, 30px);
  grid-template-columns: 20% 20%;
  column-gap: 8px;
`;

export const StyledContactLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 150px;
  ${FooterTitle} {
    text-transform: uppercase;
    color: #898a8d;
    font-size: ${theme.typography.fontSize.m};
    font-weight: ${theme.typography.fontWeight.regular};
  }
  ${FooterLink} {
    margin: 0;
    color: ${theme.colors.white};
    font-weight: ${theme.typography.fontWeight.semiBold};
  }
`;
