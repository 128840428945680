import { theme } from 'src/styles/theme';
import styled from 'styled-components';
import { animations } from 'src/styles/animations';
import { HeaderProps } from './Header.types';

export const StyledHeader = styled.header`
  padding-top: 48px;
  padding-bottom: 14px;
  transition: background-color 0.2s ease-in-out;
  position: fixed;
  z-index: ${theme.index.max};
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;

  @media ${theme.device.laptop} {
    padding-top: 0;
    padding-bottom: 0;
    background-color: ${(props: HeaderProps) => props.background};
  }
`;

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: ${theme.index.max};

  @media ${theme.device.laptop} {
    width: ${theme.width.normal};
    max-width: ${theme.width.mobileMax};
    margin: auto;
    padding-top: 20px;
  }
`;

export const StyledLogoLink = styled.a`
  width: 140px;

  svg {
    width: 100%;
  }

  svg path {
    transition: fill 0.3s;
  }

  .menu-open & {
    svg path {
      fill: ${theme.colors.white};
    }
  }

  @media ${theme.device.laptop} {
    width: 103px;
  }
`;

export const StyledMobileIcon = styled.a`
  display: none;
  margin: auto 0;

  @media ${theme.device.laptop} {
    &:active {
      background: none;
      outline: none !important;
    }

    display: block;
    position: relative;

    &::after {
      position: absolute;
      top: -20px;
      right: -20px;
      bottom: -20px;
      left: -20px;
      margin: auto;
      content: '';
      display: block;
    }

    svg {
      &:active {
        outline: none !important;
      }
    }

    .menu-open & {
      path,
      polygon {
        fill: ${theme.colors.white};
      }
    }

    .menu-icon {
      overflow: visible;

      polygon {
        transition: fill 0.6s ease-in-out;
      }

      #path-top {
        transform-origin: right;
        transform: scaleX(0.5882);
        animation: ${animations.pathTopFromCross} 1.1s ease-in-out;
      }

      #path-middle {
        transform: scaleX(1);
        transform-origin: right;
        animation: ${animations.pathMiddleFromCross} 1.1s ease-in-out;
      }

      #path-bottom {
        transform: scaleX(1.47);
        transform-origin: right;
        animation: ${animations.pathBottomFromCross} 1.1s ease-in-out;
      }

      #path-cross-left {
        transform: rotate(0);
        animation: ${animations.crossPartLeftFromCross} 1.1s ease-in-out;
        z-index: 1000;
        opacity: 0;
      }

      #path-cross-right {
        transform: rotate(0);
        animation: ${animations.crossPartRightFromCross} 1.1s ease-in-out;
        z-index: 1000;
        opacity: 0;
      }
    }

    &.mobile-menu-to-cross {
      overflow: visible;

      #path-top {
        animation: ${animations.pathTopToCross} 1.1s ease-in-out;
        transform: rotate(-45deg) translate3d(0, 5.8px, 0) scaleX(1);
        transform-origin: 70% 30%;
        opacity: 0;
      }

      .cross-part {
        transform-origin: 66% 50%;
      }

      #path-cross-left {
        transform: rotate(45deg);
        animation: ${animations.crossPartLeftToCross} 1.1s ease-in-out;
        opacity: 1;
      }

      #path-cross-right {
        transform: rotate(-45deg);
        animation: ${animations.crossPartRightToCross} 1.1s ease-in-out;
        opacity: 1;
      }

      #path-middle {
        opacity: 0;
        animation: ${animations.pathMiddleToCross} 1.1s ease-in-out;
      }

      #path-bottom {
        animation: ${animations.pathBottomToCross} 1.1s ease-in-out;
        transform: translate3d(0, -7px, 0) scaleX(1);
        transform: rotate(45deg) translate3d(0, -7px, 0) scaleX(1);
        transform-origin: 71% 64%;
        opacity: 0;
      }
    }
  }
`;

export const Styledbackground = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 100%;
  right: -100%;
  transition: -webkit-transform 0.45s ease-in-out;
  transition: transform 0.45s ease-in-out;
  transition: transform 0.45s ease-in-out, -webkit-transform 0.45s ease-in-out;
  transform: translateZ(0);
  background-color: #22252a;
  z-index: 1;

  .menu-open & {
    transform: translate3d(-100%, 0, 0);
  }

  @media ${theme.device.laptop} {
    display: block;
  }
`;
