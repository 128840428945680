import styled from 'styled-components';
import { BackButton } from 'atoms';
import { SelectionList } from 'molecules';
import { theme } from 'src/styles/theme';

export const StyledWrapper = styled.div`
  @media ${theme.device.laptop} {
    padding-top: 37px;
  }
`;

export const StyledControls = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 62px;
`;

export const StyledBackButton = styled(BackButton)`
  padding: 15px 0;
`;

export const StyledSelectionList = styled(SelectionList)`
  margin-top: 40px;
`;
