import React from 'react';
import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import { URLs } from 'src/constants';

const StyledContainer = styled.div`
  font-weight: ${theme.typography.fontWeight.regular};
  font-size: ${theme.typography.fontSize.s};
  line-height: 22px;
  color: ${theme.colors.dark};
  padding-bottom: 50px;
`;

const StyledLink = styled.a`
  font-weight: ${theme.typography.fontWeight.bold};
  color: ${theme.colors.dark};
`;

const ContactUsMobile = () => (
  <StyledContainer>
    Need help?&nbsp;
    <StyledLink href={URLs.CONTACT} target="_blank" rel="noreferrer">
      Contact us
    </StyledLink>
    &nbsp;today to get free detailed estimate and cosultation for your product
  </StyledContainer>
);
export default ContactUsMobile;
