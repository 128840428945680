import styled from 'styled-components';
import { theme } from 'src/styles/theme';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  margin-top: 6px;

  label {
    cursor: pointer;
    font-weight: ${theme.typography.fontWeight.medium};
    font-size: ${theme.typography.fontSize.xs};
    line-height: 18px;
    color: ${theme.colors.secondary};

    a {
      color: ${theme.colors.secondary};
      font-weight: ${theme.typography.fontWeight.semiBold};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media ${theme.device.laptop} {
    width: 100%;
  }
`;

export const StyledFooter = styled.div`
  width: 100%;
  font-size: ${theme.typography.fontSize.s};
  line-height: 24px;
  color: ${theme.colors.dark};
  text-align: center;
  background-color: ${theme.colors.lightGray};
  margin-top: auto;
  padding: 16px 50px;

  a {
    color: ${theme.colors.dark};
    font-weight: ${theme.typography.fontWeight.semiBold};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media ${theme.device.laptop} {
    background-color: ${theme.colors.backgroundGray};
    text-align: left;
    padding: 30px 0;
  }
`;

export const StyledFooterContainer = styled.div`
  @media ${theme.device.laptop} {
    max-width: ${theme.width.mobileMax};
    width: ${theme.width.normal};
    margin: 0 auto;
  }
`;

export const StyledText = styled.span`
  @media ${theme.device.laptop} {
    &:first-child {
      display: block;
    }
    a {
      text-decoration: underline;
    }
  }
`;
