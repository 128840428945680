import React, { useCallback, useState, useEffect, useRef } from 'react';
import { NavQuestion } from 'types/store';
import { ReactComponent as ArrowIcon } from 'images/arrow.svg';

import {
  StyledContainer,
  StyledCategoryLabel,
  StyledProgressLine,
  StyledQuestionList,
  StyledQuestionItem,
} from './CalculatorNavItem.styles';
import { CalculatorNavItemProps } from './CalculatorNavItem.types';

const CalculatorNavItem: React.FC<CalculatorNavItemProps> = ({
  children,
  questions,
  moveTo,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const targetRef = useRef<HTMLLIElement | null>(null);

  const renderQuestion = useCallback(
    (question: NavQuestion) => {
      return (
        <StyledQuestionItem
          className={question.visited ? 'visited' : ''}
          key={question.id}
          onClick={() => moveTo(question.categoryIndex, question.questionIndex)}
        >
          {`${question.number}. ${question.label}`}
        </StyledQuestionItem>
      );
    },
    [moveTo],
  );

  useEffect(() => {
    const handleMouseEnter = () => setIsOpen(true);
    const handleMouseLeave = () => setIsOpen(false);

    const target = targetRef.current;

    target?.addEventListener('mouseenter', handleMouseEnter);
    target?.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      target?.removeEventListener('mouseenter', handleMouseEnter);
      target?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [setIsOpen, targetRef.current]);

  return (
    <StyledContainer {...props} ref={targetRef}>
      <StyledProgressLine />
      <StyledCategoryLabel>
        {children}
        <ArrowIcon className={isOpen ? 'rotate' : ''} />
      </StyledCategoryLabel>
      {isOpen && <StyledQuestionList>{questions.map(renderQuestion)}</StyledQuestionList>}
    </StyledContainer>
  );
};

export default CalculatorNavItem;
