import styled from 'styled-components';
import { theme } from 'src/styles/theme';

export const StyledContainer = styled.fieldset`
  position: relative;
  padding: 0;
  border: none;
  overflow: visible;
  margin-top: 7px;

  @media ${theme.device.laptop} {
    width: 100%;
  }
`;

export const StyledLabel = styled.label`
  position: absolute;
  top: 13px;
  left: 0px;
  font-size: ${theme.typography.fontSize.m};
  color: ${theme.colors.secondary};
  line-height: 24px;
  transform-origin: 0 -150%;
  transition: transform 300ms ease;
  pointer-events: none;
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 12px 0px 8px;
  border: none;
  border-bottom: 1px solid ${theme.colors.secondary};
  font-size: ${theme.typography.fontSize.m};
  line-height: 24px;
  color: ${theme.colors.dark};
  outline: none;
  background: transparent;
  margin-bottom: 20px;

  &::-webkit-input-placeholder {
    transition: color 300ms ease;
  }

  &:not(:focus)::-webkit-input-placeholder {
    color: transparent;
  }

  &:focus ~ ${StyledLabel}, &.with ~ ${StyledLabel} {
    transform: translate(0, -17px) scale(0.8);
  }

  &.error {
    border-bottom: 1px solid ${theme.colors.error};
    margin-bottom: 0;
  }
`;

export const StyledError = styled.span`
  font-size: 11px;
  line-height: 17px;
  color: ${theme.colors.error};
  margin-top: 6px;
`;
