import React, { useCallback } from 'react';
import { Size } from 'types/common';
import { UserAction } from 'types/store';
import { userActionOptions } from 'src/constants';
import { pipe, forward } from 'utils';

import { SelectionList, OptionList } from 'molecules';

import { StyledWrap, StyledTitle, StyledContainer } from './Question.styles';
import { QuestionProps } from './Question.types';

const Question: React.FC<QuestionProps> = ({
  label,
  options,
  selectedOptions,
  onSelectAction,
  setOptions,
  moveNext,
  selectedUserAction,
  single = false,
}) => {
  const showOption = selectedUserAction?.includes(UserAction.YES) && options.length > 1 && !single;
  const canMoveNext =
    (!!selectedUserAction?.length && !showOption) ||
    (!!selectedUserAction?.length && showOption && !!selectedOptions.length);

  const setSingleAction = useCallback(pipe(forward(setOptions), onSelectAction), [
    setOptions,
    onSelectAction,
  ]);

  return (
    <StyledWrap>
      <StyledTitle>{label}</StyledTitle>
      <SelectionList
        className={single ? 'single' : ''}
        options={single ? options : userActionOptions}
        preSelectedOptions={selectedUserAction}
        handleOnChange={single ? setSingleAction : onSelectAction}
        size={showOption ? Size.S : Size.M}
      />
      <StyledContainer>
        {showOption && (
          <OptionList options={options} selectedOptions={selectedOptions} setOptions={setOptions} />
        )}
        {canMoveNext && moveNext()}
      </StyledContainer>
    </StyledWrap>
  );
};

export default Question;
