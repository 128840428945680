import styled from 'styled-components';
import { theme } from 'src/styles/theme';

const { colors, typography } = theme;

const Title = styled.h1`
  color: ${colors.dark};
  font-size: ${typography.fontSize.xl};
  font-weight: ${typography.fontWeight.extraBold};
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  @media ${theme.device.laptop} {
    margin: 0;
    font-size: ${typography.fontSize.mobXl};
    font-weight: ${typography.fontWeight.black};
    text-align: left;
  }
`;

export default Title;
