import styled from 'styled-components';
import { theme } from 'src/styles/theme';

export const StyledLabel = styled.span`
  color: ${theme.colors.dark};
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
`;

export const StyledInput = styled.input`
  &[type='checkbox'] {
    position: absolute;
    appearance: none;
  }
`;

export const StyledContainer = styled.label`
  position: relative;
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
  padding: 34px 42px;
  border: 1px solid ${theme.colors.dark};
  transition: opacity 0.3s;

  &:hover,
  &.checked,
  &:focus-within {
    padding: 32px 40px;
    border: 3px solid ${theme.colors.dark};

    svg {
      left: 0px;
      top: 0px;
    }
  }

  &:hover,
  &:focus-within {
    background-color: ${theme.colors.gray};
  }

  &:active {
    background-color: ${theme.colors.lightGray};
  }

  &.disabled {
    opacity: 0.5;
  }

  svg {
    position: absolute;
    left: 2px;
    top: 2px;
  }

  @media ${theme.device.laptop} {
    padding: 24px 40px;

    &:hover,
    &:focus-within,
    &.checked {
      padding: 22px 38px;
    }
  }
`;
