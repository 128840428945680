import { HeaderStyles, Link, NavbarWithLabel, SelectionOption, SocialLink } from 'types/common';
import { PlatformType, UserAction } from 'types/store';

import { ReactComponent as FacebookIcon } from 'images/facebook.svg';
import { ReactComponent as InstagramIcon } from 'images/instagram.svg';
import { ReactComponent as LinkedinIcon } from 'images/linkedin.svg';
import { ReactComponent as TwitterIcon } from 'images/twitter.svg';
import { ReactComponent as BehanceIcon } from 'images/behance.svg';
import { ReactComponent as DribbbleIcon } from 'images/dribbble.svg';
import { ReactComponent as GithubIcon } from 'images/github.svg';
import { ReactComponent as YoutubeIcon } from 'images/youtube.svg';

import { ReactComponent as AndriodIcon } from 'images/android.svg';
import { ReactComponent as IOSIcon } from 'images/ios.svg';
import { ReactComponent as WebIcon } from 'images/web.svg';

import { ReactComponent as Icon0 } from 'images/domain/0.svg';
import { ReactComponent as Icon1 } from 'images/domain/1.svg';
import { ReactComponent as Icon2 } from 'images/domain/2.svg';
import { ReactComponent as Icon3 } from 'images/domain/3.svg';
import { ReactComponent as Icon4 } from 'images/domain/4.svg';
import { ReactComponent as Icon5 } from 'images/domain/5.svg';
import { ReactComponent as Icon6 } from 'images/domain/6.svg';
import { ReactComponent as Icon7 } from 'images/domain/7.svg';

export const MAIN_URL = process.env.REACT_APP_MAIN_URL;
export const isProd = process.env.NODE_ENV === 'production';
export const templateId = process.env.REACT_APP_CARBONE_TEMPLATE_ID;
const API_URL = process.env.REACT_APP_API_URL;

export const getUrl = (route: string) => `${MAIN_URL}${route}`;

export const URLs = {
  WORK: getUrl('/work'),
  SERVICES: getUrl('/services'),
  COMPANY: getUrl('/company'),
  CAREER: getUrl('/career'),
  BLOG: getUrl('/blog'),
  CALCULATOR: getUrl('/calculator'),
  CONTACT: getUrl('/contact'),
  ABOUT: getUrl('/about'),
  RESOURCES: getUrl('/resources'),
  LABS: getUrl('/labs'),
  PRIVACY_POLICY: getUrl('/privacy-policy'),
  COOKIE_POLICY: getUrl('/cookie-policy'),
  SEND_REPORTS: API_URL || '',
};

export const headerLinks: Link[] = [
  { href: URLs.WORK, title: 'Work' },
  { href: URLs.SERVICES, title: 'Services' },
  { href: URLs.COMPANY, title: 'Company' },
  { href: URLs.CAREER, title: 'Career' },
  { href: URLs.BLOG, title: 'Blog' },
  { href: URLs.CALCULATOR, title: 'Calculator', isActive: true },
  { href: URLs.CONTACT, title: 'Contact', className: 'contact' },
];

export const footerLinks: NavbarWithLabel[] = [
  {
    label: 'Company',
    links: [
      { title: 'Cases', href: URLs.WORK },
      { title: 'Services', href: URLs.SERVICES },
      { title: 'About Us', href: URLs.ABOUT },
    ],
  },
  {
    label: 'Insights',
    links: [
      { title: 'Blog', href: URLs.BLOG },
      { title: 'Resources', href: URLs.RESOURCES },
      { title: 'Labs', href: URLs.LABS },
    ],
  },
  {
    label: 'Contact us',
    links: [
      { title: '+38 063 496-70-64', href: 'tel:+38 063 496-70-64' },
      { title: '+1 310 776-93-51', href: 'tel:+1 310 776-93-51' },
      { title: 'hello@uptech.team', href: 'mailto:hello@uptech.team' },
    ],
  },
];

export const policyLinks: Link[] = [
  { href: URLs.PRIVACY_POLICY, title: 'Privacy Policy' },
  { href: URLs.COOKIE_POLICY, title: 'Cookies policy' },
];

export const socialLinks: SocialLink[] = [
  { icon: FacebookIcon, href: 'https://www.facebook.com/uptechteam' },
  { icon: InstagramIcon, href: 'https://www.instagram.com/uptechteam' },
  { icon: LinkedinIcon, href: 'https://www.linkedin.com/company/uptech-team' },
  { icon: TwitterIcon, href: 'https://twitter.com/uptechteam' },
  { icon: BehanceIcon, href: 'https://www.behance.net/uptech' },
  { icon: DribbbleIcon, href: 'https://dribbble.com/uptechteam' },
  { icon: GithubIcon, href: 'https://github.com/uptechteam' },
  { icon: YoutubeIcon, href: 'https://www.youtube.com/c/UptechTeam' },
];

export const socialLinksMobileMenu: Link[] = [
  { title: 'GitHub', href: 'https://github.com/uptechteam' },
  { title: 'Twitter', href: 'https://twitter.com/uptechteam' },
  { title: 'LinkedIn', href: 'https://www.linkedin.com/company/uptech-team' },
  { title: 'Medium', href: 'https://www.uptech.team/blog' },
  { title: 'Facebook', href: 'https://www.facebook.com/uptechteam' },
  { title: 'Instagram', href: 'https://www.instagram.com/uptechteam' },
];

export const mobileMenuLinks: NavbarWithLabel[] = [
  {
    label: 'Project inquiries',
    links: [{ title: 'hello@uptech.team', href: 'mailto:hello@uptech.team' }],
  },
  {
    label: 'Join the team',
    links: [{ title: 'talents@uptech.team', href: 'mailto:talents@uptech.team' }],
  },
];

export const platformOptions: SelectionOption[] = [
  {
    value: PlatformType.IOS,
    label: 'iOS app',
    Icon: IOSIcon,
  },
  {
    value: PlatformType.Android,
    label: 'Android app',
    Icon: AndriodIcon,
  },
  {
    value: PlatformType.Web,
    label: 'Web app',
    Icon: WebIcon,
  },
];

export const userActionOptions: SelectionOption[] = [
  { value: UserAction.YES },
  { value: UserAction.NO },
  { value: UserAction.I_DONT_KNOW },
];

export const DomainIcons = [Icon0, Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7];

export enum AppRoutes {
  SELECT_DOMAIN = '/',
  SELECT_PLATFORMS = '/select-platforms',
  CALCULATOR = '/questions',
  SUMMARY = '/summary',
}

export const headerStyles: { [key: string]: HeaderStyles } = {
  '/summary': {
    bgMain: '#FDC82F',
    bgOnChange: '#fff',
    changeHeight: 400,
  },
};
