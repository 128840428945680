import styled from 'styled-components';
import { theme } from 'src/styles/theme';

const { colors, typography } = theme;

const FooterLink = styled.a`
  color: ${colors.footer.colorLink};
  font-size: ${typography.fontSize.xs};
  font-weight: ${typography.fontWeight.regular};
  line-height: ${typography.fontSize.m};
  text-decoration: none;
  display: block;

  &:hover {
    color: ${colors.footer.colorLink};
  }
`;

export default FooterLink;
