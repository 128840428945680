import styled from 'styled-components';
import { theme } from 'src/styles/theme';

export const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 70px;
`;

export const StyledTitle = styled.p`
  margin: 0 auto;
  width: ${theme.width.normal};
  max-width: ${theme.width.mobileMax};
  color: ${theme.colors.dark};
  font-size: ${theme.typography.fontSize.r};
  font-weight: ${theme.typography.fontWeight.extraBold};
  line-height: 27px;
`;

export const StyledQuestionsList = styled.div`
  width: 100%;
  max-width: ${theme.width.mobileMax};
  margin: 20px auto 50px auto;
  &:last-child {
    padding-bottom: 15vh;
  }
`;

export const StyledQuestionItem = styled.li`
  color: ${theme.colors.disable};
  font-size: ${theme.typography.fontSize.s};
  font-weight: ${theme.typography.fontWeight.medium};
  line-height: 24px;
  padding: 12px 30px;
  border-bottom: 1px solid ${theme.colors.lightGray};
  pointer-events: none;
  list-style: none;

  &:last-child {
    border-bottom: none;
  }

  &.visited {
    color: ${theme.colors.dark};
    cursor: pointer;
    pointer-events: initial;
  }
`;
