import { makeObservable, observable, computed, action } from 'mobx';
import { SelectionOption } from 'types/common';
import {
  Category,
  Question,
  UserAction,
  NavCategory,
  NavQuestion,
  GroupedEstimate,
  PlatformType,
  RawQuestionData,
  ReportType,
  EstimateType,
  PlatformsEstimate,
} from 'types/store';
import { pick } from 'utils';
import rawQuestionsData from 'src/questions.json';

import {
  getCategoriesEstimate,
  getDomainOptions,
  getTotalEstimateByPlatform,
  getTotalEstimate,
  getReport,
} from './CalculatorStore.utils';

export default class CalculatorStore {
  constructor() {
    makeObservable(this, {
      // General
      rawQuestions: observable,
      domainOptions: computed,
      platforms: observable,
      setPlatforms: action,
      domain: observable,
      setDomain: action,
      reset: action,
      // Question
      groupedQuestions: observable,
      setGroupedQuestions: action,
      currentCategoryIndex: observable,
      currentQuestionIndex: observable,
      currentQuestion: computed,
      markAsVisited: action,
      setUserAction: action,
      setOptions: action,
      // Navigation
      isLastQuestion: computed,
      isFirstQuestion: computed,
      navigation: computed,
      lastVisitedCategory: observable,
      moveTo: action,
      moveNext: action,
      moveBack: action,
      // Summary
      groupedEstimates: computed,
      subtotal: computed,
      total: computed,
      report: computed,
    });
    this.rawQuestions = rawQuestionsData as unknown as RawQuestionData[];
    this.groupedQuestions = [];
    this.totalQuestions = 0;
    this.currentCategoryIndex = 0;
    this.currentQuestionIndex = 0;
    this.lastVisitedCategory = 0;
  }

  rawQuestions: RawQuestionData[];
  domain?: string;
  platforms?: PlatformType[];
  groupedQuestions: Category[];
  totalQuestions: number;
  currentCategoryIndex: number;
  currentQuestionIndex: number;
  lastVisitedCategory: number;

  setDomain = (domain: string) => {
    this.domain = domain;
  };

  setPlatforms = (platforms: PlatformType[]) => {
    this.platforms = platforms;
  };

  get domainOptions(): SelectionOption[] {
    return getDomainOptions(this.rawQuestions);
  }

  reset = () => {
    this.totalQuestions = 0;
    this.currentCategoryIndex = 0;
    this.currentQuestionIndex = 0;
    this.lastVisitedCategory = 0;
  };

  setGroupedQuestions = () => {
    if (this.domain) {
      this.reset();
      const currentDomain = rawQuestionsData.find(d => d.domain === this.domain);

      if (currentDomain) {
        const groupedQuestions = currentDomain.groupedQuestions as unknown as Category[];
        this.groupedQuestions = groupedQuestions;
        this.totalQuestions =
          groupedQuestions[groupedQuestions.length - 1].questions[
            groupedQuestions[groupedQuestions.length - 1].questions.length - 1
          ].number;
      }
    }
  };

  markAsVisited = () => {
    this.groupedQuestions[this.currentCategoryIndex].questions[this.currentQuestionIndex].visited =
      true;
    if (this.currentCategoryIndex > this.lastVisitedCategory) {
      this.lastVisitedCategory = this.currentCategoryIndex;
    }
  };

  get currentQuestion(): Question | undefined {
    if (this.groupedQuestions.length) {
      this.markAsVisited();
      return this.groupedQuestions[this.currentCategoryIndex].questions[this.currentQuestionIndex];
    }
  }

  setUserAction = (value: UserAction[]) => {
    if (this.currentQuestion) {
      this.currentQuestion.selectedUserAction = value;
    }
  };

  setOptions = (values: string[]) => {
    if (this.currentQuestion) {
      this.currentQuestion.selectedOptions = values;
    }
  };

  // Navigation
  get navigation(): NavCategory[] {
    return this.groupedQuestions.map(({ id, label, questions }) => ({
      id,
      label,
      visited: !!questions[0].visited,
      questions: questions.map(question =>
        pick<NavQuestion>(question, [
          'id',
          'label',
          'visited',
          'categoryIndex',
          'questionIndex',
          'number',
        ]),
      ),
    }));
  }

  get isFirstQuestion(): boolean {
    if (this.currentQuestion) {
      return this.currentQuestion.number === 1;
    }
    return false;
  }

  get isLastQuestion(): boolean {
    if (this.currentQuestion) {
      return this.currentQuestion.number === this.totalQuestions;
    }
    return false;
  }

  moveTo = (categoryIndex: number, questionIndex: number) => {
    this.currentCategoryIndex = categoryIndex;
    this.currentQuestionIndex = questionIndex;
  };

  moveNext = () => {
    if (!this.isLastQuestion) {
      if (
        this.currentQuestionIndex ===
        this.groupedQuestions[this.currentCategoryIndex].questions.length - 1
      ) {
        this.currentCategoryIndex++;
        this.currentQuestionIndex = 0;
      } else {
        this.currentQuestionIndex++;
      }
    }
  };

  moveBack = () => {
    if (this.currentQuestionIndex === 0) {
      this.currentCategoryIndex--;
      this.currentQuestionIndex =
        this.groupedQuestions[this.currentCategoryIndex].questions.length - 1;
    } else {
      this.currentQuestionIndex--;
    }
  };

  // Summary
  get groupedEstimates(): GroupedEstimate[] {
    return getCategoriesEstimate(this.groupedQuestions);
  }

  get subtotal(): PlatformsEstimate {
    return getTotalEstimateByPlatform(this.groupedEstimates);
  }

  get total(): EstimateType {
    return getTotalEstimate(this.platforms, this.subtotal);
  }

  get report(): ReportType {
    return getReport(
      this.groupedQuestions,
      this.subtotal,
      this.total,
      this.platforms,
      this.domain as string,
    );
  }
}
