import React from 'react';
import CountUp from 'react-countup';
import { PlatformItemProps } from './PlatformItem.types';
import { StyledContainer, StyledLabel, StyledTime, StyledTimeWrapper } from './PlatformItem.styles';

const PlatformItem: React.FC<PlatformItemProps> = ({ label, estimate, ...props }) => (
  <StyledContainer {...props}>
    <StyledLabel>{label}</StyledLabel>
    <StyledTimeWrapper>
      <StyledTime>
        min <CountUp start={0} end={estimate[0]} />h
      </StyledTime>
      <StyledTime>
        max <CountUp start={0} end={estimate[1]} />h
      </StyledTime>
    </StyledTimeWrapper>
  </StyledContainer>
);

export default PlatformItem;
