import { SelectionOption } from 'types/common';

export const getOptionsList = (
  options: SelectionOption[],
  selectedOptions: string[],
): SelectionOption[] =>
  options.map(option => ({
    ...option,
    checked: selectedOptions.includes(option.value),
  }));
