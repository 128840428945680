import styled from 'styled-components';
import { NavQuestionItemProps } from './Calculator.types';
import { theme } from 'src/styles/theme';
import { BackButton } from 'atoms';

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 50px;

  @media ${theme.device.laptop} {
    flex-direction: column;
  }
`;

export const NavQuestionItem = styled.li<NavQuestionItemProps>`
  cursor: pointer;
  color: ${({ visited }) => (visited ? 'black' : 'gray')};
  pointer-events: ${({ visited }) => (visited ? 'auto' : 'none')};
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  padding: 10px 0;
`;

export const NavButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;

  @media ${theme.device.laptop} {
    flex-direction: column-reverse;
    height: auto;
  }
`;

export const StyledBackButton = styled(BackButton)`
  margin-right: auto;

  @media ${theme.device.laptop} {
    margin-top: 40px;
  }
`;

export const StyledLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`;

export const StyledSummary = styled.div`
  margin-left: 50px;
  flex: 1 2 100%;

  @media ${theme.device.laptop} {
    padding-top: 50px;
    margin-left: 0;
    display: none;
  }
`;
