import React, { Fragment, useCallback } from 'react';
import { NavCategory, NavQuestion } from 'types/store';

import {
  StyledWrap,
  StyledTitle,
  StyledQuestionItem,
  StyledQuestionsList,
} from './QuestionsMobile.styles';
import { QuestionProps } from './QuestionsMobile.types';

const QuestionsMobile: React.FC<QuestionProps> = ({
  categories,
  currentCategoryIndex,
  moveTo,
  handleClosePopUp,
}) => {
  const renderQuestion = useCallback(
    (question: NavQuestion) => {
      return (
        <StyledQuestionItem
          className={question.visited ? 'visited' : ''}
          key={question.id}
          onClick={() => {
            handleClosePopUp();
            moveTo(question.categoryIndex, question.questionIndex);
          }}
        >
          {`${question.number}. ${question.label}`}
        </StyledQuestionItem>
      );
    },
    [moveTo],
  );

  const renderCategories = useCallback(
    ({ id, questions, label }: NavCategory) => {
      return (
        <Fragment key={id}>
          <StyledTitle>{label}</StyledTitle>
          <StyledQuestionsList>{questions.map(renderQuestion)}</StyledQuestionsList>
        </Fragment>
      );
    },
    [categories, currentCategoryIndex],
  );

  return <StyledWrap>{categories.map(category => renderCategories(category))}</StyledWrap>;
};

export default QuestionsMobile;
