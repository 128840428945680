import React, { useCallback } from 'react';
import { StyledContainer, StyledTab, StyledSingleTab, StyledTabsContainer } from './Tabs.styles';
import { TabsProps } from './Tabs.types';

const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, onChange }) => {
  const renderTab = useCallback(
    (tab: string) => {
      const isActive = activeTab === tab;
      return (
        <StyledTab className={isActive ? 'active' : ''} key={tab} value={tab} onClick={onChange}>
          {tab}
        </StyledTab>
      );
    },
    [activeTab],
  );

  return (
    <StyledContainer>
      {tabs.length === 1 ? (
        <StyledSingleTab>Platform: {tabs[0]}</StyledSingleTab>
      ) : (
        <StyledTabsContainer>{tabs.map(renderTab)}</StyledTabsContainer>
      )}
    </StyledContainer>
  );
};

export default Tabs;
