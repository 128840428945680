import { theme } from 'src/styles/theme';
import styled from 'styled-components';

export const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  list-style: none;
  min-width: 652px;
  justify-content: space-between;

  &.mobile {
    display: block;
  }

  @media ${theme.device.laptop} {
    min-width: initial;

    &.desktop {
      display: none;
    }
  }
`;
