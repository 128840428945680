import React, { useEffect } from 'react';
import { StyledWrap, StyledContentWrapper } from './PopUp.styles';
import { Button } from 'atoms';
import { PopUpProps } from './PopUp.types';

const PopUp: React.FC<PopUpProps> = ({ isOpen, setPopUpAction, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <StyledWrap>
      <StyledContentWrapper>
        {children}
        <Button onClick={() => setPopUpAction(null)}>Close</Button>
      </StyledContentWrapper>
    </StyledWrap>
  );
};

export default PopUp;
