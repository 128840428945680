import styled from 'styled-components';
import { theme } from 'src/styles/theme';

const { colors, typography } = theme;

const FooterTitle = styled.h3`
  color: ${colors.footer.color};
  font-size: ${typography.fontSize.xs};
  font-weight: ${typography.fontWeight.semiBold};
  line-height: ${typography.fontSize.m};
`;

export default FooterTitle;
