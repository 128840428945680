import styled from 'styled-components';
import { theme } from 'src/styles/theme';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  font-weight: ${theme.typography.fontWeight.semiBold};
  font-size: ${theme.typography.fontSize.xs};
  line-height: 16px;
  text-transform: uppercase;
  color: ${theme.colors.primary};
  pointer-events: none;

  span {
    color: ${theme.colors.dark};
  }

  svg {
    display: none;
    margin-left: 5px;
    vertical-align: 1px;

    path {
      stroke: ${theme.colors.dark};
    }
  }

  @media ${theme.device.laptop} {
    margin: 0 auto;
    cursor: pointer;
    pointer-events: auto;

    svg {
      display: block;
    }
  }
`;
