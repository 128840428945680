import React, { useCallback, ChangeEvent } from 'react';
import { InputProps } from './Input.types';
import { StyledContainer, StyledInput, StyledLabel, StyledError } from './Input.styles';
import classnames from 'classnames';

const Input: React.FC<InputProps> = ({
  value,
  label,
  error,
  className,
  onChange,
  ...attributes
}) => {
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e.target.value);
    },
    [onChange],
  );

  const classNames = classnames(className, {
    with: value,
    error: error,
  });

  return (
    <StyledContainer className={error ? 'error' : ''}>
      <StyledInput onChange={handleOnChange} className={classNames} value={value} {...attributes} />
      <StyledLabel>{label}</StyledLabel>
      {error && <StyledError>{error}</StyledError>}
    </StyledContainer>
  );
};

export default Input;
