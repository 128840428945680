import styled from 'styled-components';
import { theme } from 'src/styles/theme';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: auto;
  width: ${theme.width.normal};

  &.mainWrapper {
    margin-top: 120px;
    margin-bottom: 40px;
  }

  @media ${theme.device.laptop} {
    &.mainWrapper {
      margin-top: 60px;
      margin-bottom: 40px;
    }

    &.full-width {
      width: 100%;
      max-width: none;
      margin-bottom: 0;
    }

    &.in-footer {
      #nextButton {
        background-color: ${theme.colors.secondary};
      }
    }

    &.out-footer {
      #nextButton {
        background-color: ${theme.colors.dark};
      }
    }

    max-width: ${theme.width.mobileMax};
  }
`;

export default Container;
