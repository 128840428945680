import React from 'react';
import { Size } from 'types/common';

import { Container, Estimate } from 'atoms';

import { StyledContainer, StyledTotal, StyledButton } from './TotalSummaryMobile.styles';
import { PlatfromSummaryProps } from './TotalSummaryMobile.types';

import ContactUsMob from 'atoms/ContactUsMobile';

const TotalSummaryMobile: React.FC<PlatfromSummaryProps> = ({ total, handleOpenPopUp }) => (
  <StyledContainer>
    <Container>
      <StyledTotal>
        total
        <Estimate size={Size.M} estimate={total} />
      </StyledTotal>
      <StyledButton onClick={handleOpenPopUp}>open summary</StyledButton>
      <ContactUsMob />
    </Container>
  </StyledContainer>
);

export default TotalSummaryMobile;
