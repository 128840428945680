import styled from 'styled-components';
import { theme } from 'src/styles/theme';

const checkmarkImg =
  '\'data:image/svg+xml,%3Csvg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M8.78864 0L3.33577 5.53756L1.21612 3.38519L0 4.56084L3.33114 8L10 1.28663L8.78864 0Z" fill="white"/%3E%3C/svg%3E%0A\'';

export const StyledIndicator = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  border: 1px solid ${theme.colors.checkbox.unchecked};
  background: ${theme.colors.white};
  margin-left: 2px;
  margin-right: 8px;

  @media ${theme.device.laptop} {
    width: 24px;
    height: 24px;
  }
`;

export const StyledContainer = styled.label`
  position: relative;
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;

  &:hover {
    ${StyledIndicator} {
      box-shadow: 0 0 0 2px ${theme.colors.checkbox.hover};
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &.radial {
    ${StyledIndicator} {
      border-radius: 50%;
    }
  }
`;

export const StyledInput = styled.input`
  &[type='checkbox'] {
    position: absolute;
    appearance: none;
    background: transparent;
  }

  &:focus + ${StyledIndicator} {
    border-color: ${theme.colors.checkbox.unchecked};
    box-shadow: 0 0 0 2px ${theme.colors.checkbox.hover};
  }

  &:checked + ${StyledIndicator} {
    border-color: ${theme.colors.checkbox.checked};
    background: ${theme.colors.checkbox.checked} url(${checkmarkImg}) no-repeat center;
  }

  &:disabled + ${StyledIndicator} {
    border-color: ${theme.colors.checkbox.unchecked};
    background: ${theme.colors.checkbox.unchecked};
  }

  &:disabled:checked + ${StyledIndicator} {
    border-color: ${theme.colors.checkbox.unchecked};
    background: ${theme.colors.checkbox.unchecked} url(${checkmarkImg}) no-repeat center;
  }
`;

export const StyledLabel = styled.span`
  color: ${theme.colors.dark};
  font-weight: 500;
  font-size: ${theme.typography.fontSize.xs};
  line-height: 18px;
  flex-shrink: 1000;

  @media ${theme.device.laptop} {
    font-size: ${theme.typography.fontSize.s};
  }
`;
