import React from 'react';
import { QuestionCountProps } from './QuestionCount.types';
import { StyledContainer } from './QuestionCount.styles';
import { ReactComponent as ArrowIcon } from 'images/arrow.svg';

const QuestionCount: React.FC<QuestionCountProps> = ({
  current,
  total,
  handleOpenPopUp,
  ...rest
}) => (
  <StyledContainer {...rest} onClick={handleOpenPopUp}>
    Question {current}&nbsp;<span>of {total}</span>
    <ArrowIcon />
  </StyledContainer>
);

export default QuestionCount;
