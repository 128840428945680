import styled from 'styled-components';
import { theme } from 'src/styles/theme';

export const StyledList = styled.ul`
  display: grid;
  grid-template-rows: repeat(2, 24px);
  grid-template-columns: repeat(4, 24px);
  grid-gap: 24px;
  margin-left: auto;

  @media ${theme.device.laptop} {
    grid-area: icons-column;
    width: 100%;
    row-gap: 40px;
    column-gap: unset;
    justify-content: space-between;
    margin-left: 0;
  }
`;

export const StyledLink = styled.a`
  width: 24px;
  height: 24px;
  display: block;

  .hover-inner {
    opacity: 0;
    transition: all 0.3s ease;
  }

  &:hover {
    .hover-inner {
      opacity: 1;
    }
  }
`;
