import {
  RawQuestionData,
  Category,
  Question,
  UserAction,
  QuestionOption,
  PlatformsEstimate,
  QuestionEstimate,
  PlatformType,
  OptionReportType,
  QuestionReportType,
  CategoryReportType,
  PlatformReportType,
  GeneralCategoryReport,
  GeneralQuestionReport,
  ReportType,
  EstimateType,
  GroupedEstimate,
} from 'types/store';
import { DomainIcons } from 'src/constants';
import { SelectionOption } from 'types/common';

export const getDomainOptions = (data: RawQuestionData[]): SelectionOption[] =>
  data.map((domainData, index) => ({
    value: domainData.domain,
    Icon: DomainIcons[index],
  }));

const joinEstimate = (a: EstimateType, b: EstimateType): EstimateType => [a[0] + b[0], a[1] + b[1]];

const joinPlatformEstimate = (a: PlatformsEstimate, b: PlatformsEstimate): PlatformsEstimate => {
  return {
    [PlatformType.Android]: joinEstimate(a[PlatformType.Android], b[PlatformType.Android]),
    [PlatformType.IOS]: joinEstimate(a[PlatformType.IOS], b[PlatformType.IOS]),
    [PlatformType.Web]: joinEstimate(a[PlatformType.Web], b[PlatformType.Web]),
  };
};

export const getTotalEstimateByPlatform = (
  categories: GroupedEstimate[] = [],
): PlatformsEstimate => {
  let total: PlatformsEstimate = {
    [PlatformType.Android]: [0, 0],
    [PlatformType.IOS]: [0, 0],
    [PlatformType.Web]: [0, 0],
  };

  categories.forEach(category =>
    category.questions.forEach(question =>
      question.estimates.forEach(esitame => {
        total = joinPlatformEstimate(total, esitame);
      }),
    ),
  );

  return total;
};

export const getTotalEstimate = (
  selectedPlatforms: PlatformType[] = [],
  platfromEstimate: PlatformsEstimate,
): EstimateType => {
  return selectedPlatforms.reduce(
    (acc: EstimateType, platformType: PlatformType) => {
      return joinEstimate(acc, platfromEstimate[platformType]);
    },
    [0, 0],
  );
};

const getQuestionsEstimate = (questions: Question[]): QuestionEstimate[] =>
  questions
    .filter(
      question =>
        question.selectedUserAction &&
        question.selectedUserAction.includes(UserAction.YES) &&
        !question.isHide,
    )
    .map(question => {
      const estimates = question.options.reduce(
        (acc: PlatformsEstimate[], item: QuestionOption) => {
          if (question.selectedOptions?.includes(item.id)) {
            return [...acc, item.estimate];
          }
          return acc;
        },
        [],
      );

      return {
        id: question.id,
        label: question.shortLabel || question.label,
        estimates,
      };
    });

export const getCategoriesEstimate = (categories: Category[]) =>
  categories
    .filter(category => category.questions[0].visited)
    .map(category => ({
      id: category.id,
      label: category.label,
      questions: getQuestionsEstimate(category.questions),
    }))
    .filter(category => category.questions.length);

const findOption = (id: string | undefined, options: QuestionOption[]) =>
  options.find(o => o.id === id);

const getOptionsReport = (
  selectedOptions: string[],
  options: QuestionOption[],
  platform: PlatformType,
): OptionReportType[] =>
  selectedOptions
    .map(id => {
      const option = findOption(id, options);
      return {
        label: option?.label || option?.value || '',
        min: option?.estimate[platform][0] || 0,
        max: option?.estimate[platform][1] || 0,
      };
    })
    .filter(options => options.label);

const getQuestionsReport = (questions: Question[], platform: PlatformType): QuestionReportType[] =>
  questions
    .filter(
      question =>
        question.selectedUserAction?.length &&
        question.selectedUserAction.includes(UserAction.YES) &&
        question.selectedOptions?.length &&
        !question.isHide,
    )
    .map(question => ({
      label: question.shortLabel || question.label,
      options: getOptionsReport(question.selectedOptions as string[], question.options, platform),
    }));

const getCategoriesReport = (
  categories: Category[],
  platform: PlatformType,
): CategoryReportType[] =>
  categories
    .filter(category => category.questions[0].visited)
    .map(category => ({
      label: category.label,
      questions: getQuestionsReport(category.questions, platform),
    }))
    .filter(category => category.questions.length);

const getPlatformReport = (
  categories: Category[],
  platforms: PlatformType[],
  subtotal: PlatformsEstimate,
): PlatformReportType[] =>
  platforms.map(platform => ({
    label: platform,
    categories: getCategoriesReport(categories, platform),
    min: subtotal[platform][0],
    max: subtotal[platform][1],
  }));

const getGeneralQuestionsReport = (questions: Question[]): GeneralQuestionReport[] =>
  questions
    .filter(question => question.selectedUserAction && question.isHide)
    .map(question => ({
      label: question.shortLabel || question.label,
      answer: findOption(question.selectedOptions?.[0], question.options)?.label || '',
    }));

const getMarketingQuestionsReport = (questions: Question[]): GeneralQuestionReport[] =>
  questions
    .filter(
      question =>
        question.selectedUserAction && question.selectedUserAction.includes(UserAction.I_DONT_KNOW),
    )
    .map(question => ({
      label: question.shortLabel || question.label,
      answer: UserAction.I_DONT_KNOW,
    }));

const getGeneralQuestionReport = (
  categories: Category[],
  isForMarketing = false,
): GeneralCategoryReport[] =>
  categories
    .filter(category => category.questions[0].visited)
    .map(category => ({
      label: category.label,
      questions: isForMarketing
        ? getMarketingQuestionsReport(category.questions)
        : getGeneralQuestionsReport(category.questions),
    }))
    .filter(category => category.questions.length);

export const getReport = (
  categories: Category[],
  subtotal: PlatformsEstimate,
  total: EstimateType,
  platforms: PlatformType[] = [],
  domain: string,
): ReportType => ({
  platforms: getPlatformReport(categories, platforms, subtotal),
  general: getGeneralQuestionReport(categories),
  unanswered: getGeneralQuestionReport(categories, true),
  min: total[0],
  max: total[1],
  category: domain.toUpperCase(),
});
