import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { ReactComponent as BackIcon } from 'images/back.svg';
import { theme } from 'src/styles/theme';

const StyledButton = styled.button`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: ${theme.colors.dark};
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 18px;
    height: 13px;
    margin-right: 9px;
  }

  @media ${theme.device.laptop} {
    opacity: 1;
  }
`;

const BackButton: React.FC<HTMLAttributes<HTMLButtonElement>> = props => {
  return (
    <StyledButton type="button" value="Back" {...props}>
      <BackIcon />
      Back
    </StyledButton>
  );
};

export default BackButton;
