import styled from 'styled-components';
import { Orientation } from 'types/common';
import { theme } from 'src/styles/theme';
import { FooterLink, FooterTitle } from 'atoms';

export const StyledFooterContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${FooterLink} {
    margin-bottom: ${theme.spacing(2)};
  }

  ${FooterTitle} {
    margin-bottom: ${theme.spacing(2)};
  }

  &.${Orientation.Horizontal} {
    flex-direction: row;

    ${FooterLink} {
      margin-bottom: 0px;
      margin-right: ${theme.spacing(4)};
    }
  }

  @media ${theme.device.laptop} {
    &.${Orientation.Horizontal} {
      display: grid;
      width: 100%;
      justify-content: stretch;
      justify-items: start;
      align-items: start;
      flex: 0 auto;
      grid-auto-columns: 1fr;
      grid-column-gap: 16px;
      grid-row-gap: 8px;
      grid-template-columns: auto auto;
      grid-template-rows: auto;
    }

    &.company {
      grid-area: company-column;
    }
    &.insights {
      grid-area: insights-column;
    }
    &.contactus {
      grid-area: contact-column;
    }
  }
`;
