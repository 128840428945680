import { useState, useCallback } from 'react';

export const useInputWithValidation = (
  validator: (v: string) => boolean,
): [string, (value: string) => void, boolean] => {
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(true);

  const onChange = useCallback(
    (value: string) => {
      setIsValid(validator(value));
      setValue(value);
    },
    [setValue, setIsValid, validator],
  );

  return [value, onChange, isValid];
};
