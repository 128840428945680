import React, { HTMLAttributes, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import CountUp from 'react-countup';
import cn from 'classnames';

import { AppRoutes, URLs } from 'src/constants';
import { useGlobalStore } from 'store/GlobalStore';
import { PlatformType } from 'types/store';

import { BackButton, Container, Letter } from 'atoms';
import { PlatformItem } from 'molecules';
import { Form } from 'organisms';

import {
  StyledContainer,
  StyledSummaryContainer,
  StyledTitle,
  StyledTime,
  StyledMonth,
  StyledPlatfromList,
  StyledButtonContainer,
  StyledInnerButtonContainer,
  StyledFormWrapper,
  StyledFormContainer,
  StyledFormTitle,
  StyledDescription,
  StyledFooter,
  StyledButton,
  StyledText,
  StyledErrorMessage,
} from './Summary.styles';
import { getMonths } from './Summary.utils';

const Summary: React.FC<HTMLAttributes<HTMLElement>> = props => {
  const navigate = useNavigate();
  const {
    calculatorStore: { platforms, total, subtotal, report },
    sendReport,
    errorMessage,
    isSuccess,
    submitLoading,
  } = useGlobalStore();

  const moveBack = useCallback(() => {
    navigate(AppRoutes.CALCULATOR);
  }, [navigate]);

  const renderPlatforms = useCallback(
    (platform: PlatformType) => {
      return <PlatformItem estimate={subtotal[platform]} label={platform} key={platform} />;
    },
    [subtotal],
  );

  const onSubmit = useCallback(
    (userData: { [key: string]: string }) => {
      sendReport({
        name: userData.name,
        email: userData.email,
        report,
      });
    },
    [sendReport, report],
  );

  useEffect(() => {
    if (!platforms) {
      navigate(AppRoutes.SELECT_DOMAIN);
    }
  }, [navigate, platforms]);

  const isSingle = platforms?.length === 1;
  const totalMonths = getMonths(total);

  return (
    <Container className={cn('mainWrapper', 'full-width')}>
      {!isSuccess && (
        <StyledButtonContainer>
          <StyledInnerButtonContainer>
            <BackButton onClick={moveBack} />
          </StyledInnerButtonContainer>
        </StyledButtonContainer>
      )}
      <StyledContainer {...props}>
        <StyledSummaryContainer>
          {isSuccess ? (
            <Letter />
          ) : (
            <>
              <StyledTitle>Total time</StyledTitle>
              <StyledTime style={{ marginBottom: '20px' }}>
                min <CountUp start={0} end={total[0]} />h
              </StyledTime>
              <StyledTime>
                max <CountUp start={0} end={total[1]} />h
              </StyledTime>
              {isSingle ? (
                <StyledMonth>
                  It will take around <b>{`${totalMonths} month${totalMonths > 1 ? 's' : ''}`}</b>
                </StyledMonth>
              ) : (
                <StyledPlatfromList>{platforms?.map(renderPlatforms)}</StyledPlatfromList>
              )}
            </>
          )}
        </StyledSummaryContainer>
        <StyledFormContainer>
          <StyledFormWrapper>
            <StyledFormTitle>
              {isSuccess ? "All good!\nYou've made it!" : 'Get rough estimation of your app'}
            </StyledFormTitle>
            <StyledDescription>
              {isSuccess
                ? 'The detailed cost estimate has been sent to your inbox'
                : 'With full feature list'}
            </StyledDescription>
            {!isSuccess && <Form loading={submitLoading} onSubmit={onSubmit} />}
            {isSuccess && (
              <StyledButton as="a" href={URLs.WORK} target="_blank" rel="noreferrer">
                Check our portfolio
              </StyledButton>
            )}
            {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
          </StyledFormWrapper>
          {isSuccess ? (
            <StyledFooter>
              <StyledText>Still have questions?</StyledText>
              <StyledText>
                <a href={URLs.CONTACT} target="_blank" rel="noreferrer">
                  Contact us
                </a>
              </StyledText>
            </StyledFooter>
          ) : (
            <StyledFooter>
              <StyledText>
                Please don’t take this app cost as fact. It’s a rough estimate.
              </StyledText>
              <StyledText>
                Please{' '}
                <a href={URLs.CONTACT} target="_blank" rel="noreferrer">
                  contact our sales team
                </a>{' '}
                for a detailed estimate for your bold idea.
              </StyledText>
            </StyledFooter>
          )}
        </StyledFormContainer>
      </StyledContainer>
    </Container>
  );
};

export default observer(Summary);
