import styled from 'styled-components';
import { theme } from 'src/styles/theme';
import { Size } from 'types/common';

export const StyledEstimate = styled.div`
  font-weight: ${theme.typography.fontWeight.medium};
  font-size: ${theme.typography.fontSize.s};
  color: ${theme.colors.dark};
  line-height: 24px;
  margin-top: 10px;
  text-transform: none;

  &.${Size.M} {
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize.r};
    line-height: 30px;
  }
`;
