import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Size, Orientation, SelectionOption } from 'types/common';

import { Checkbox, BigCheckbox } from 'atoms';

import { useOptionsList } from './hooks/useOptionsList';
import { SelectionListProps } from './SelectionList.types';
import { StyledContainer } from './SelectionList.styles';

const SelectionList: React.FC<SelectionListProps> = ({
  className,
  options,
  preSelectedOptions,
  handleOnChange,
  size = Size.M,
  orientation = Orientation.Horizontal,
  multiselect = false,
  disabled = false,
  ...props
}) => {
  const [optionsList, onSelectOption] = useOptionsList({
    preSelectedOptions,
    options,
    handleOnChange,
    multiselect,
  });

  const hasSelected = useMemo(
    () => optionsList.reduce((acc, option) => acc || option?.checked || false, false),
    [optionsList],
  );

  const renderMediumOption = useCallback(
    (option: SelectionOption) => {
      return (
        <BigCheckbox
          className="big-checkbox"
          {...option}
          id={option.id || option.value}
          label={option.label || option.value}
          key={option.value || option.label}
          disabled={hasSelected && !option.checked}
          onChange={onSelectOption}
        />
      );
    },
    [onSelectOption, hasSelected, size],
  );

  const renderSmallOption = useCallback(
    (option: SelectionOption) => {
      return (
        <Checkbox
          className="checkbox"
          {...option}
          label={option.label || option.value}
          key={option.value || option.label}
          disabled={option.disabled || disabled}
          onChange={onSelectOption}
          radial={!multiselect}
        />
      );
    },
    [onSelectOption, hasSelected, size, disabled, multiselect],
  );

  const isSmall = size === Size.S;
  const classes = classNames(className, orientation, size);

  return (
    <StyledContainer className={classes} {...props}>
      {optionsList.map(isSmall ? renderSmallOption : renderMediumOption)}
    </StyledContainer>
  );
};

export default SelectionList;
