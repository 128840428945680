import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { noop, forward, pipe } from 'utils';
import { SelectionOption } from 'types/common';

import { getOptionsList } from './useOptionsList.utils';
import { useSelectOptionProps } from './useSelectOption.types';

// While empty preSelectedOptions gives "Maximum update depth props default"
const EmptyArray: string[] = [];

const useSelectState = <T>(
  selectedOptions: T,
  onChange: (values: T) => void,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [checkedOptions, setCheckedOptions] = useState<T>(selectedOptions);

  useEffect(() => setCheckedOptions(selectedOptions), [selectedOptions]);

  const setOptions = useCallback(pipe(forward<T>(setCheckedOptions), onChange), [onChange]);

  return [checkedOptions, setOptions];
};

export const useOptionsList = ({
  options,
  preSelectedOptions = EmptyArray,
  handleOnChange = noop,
  multiselect = false,
}: useSelectOptionProps): [SelectionOption[], (e: ChangeEvent<HTMLInputElement>) => void] => {
  const [selectedOptions, setSelectedOptions] = useSelectState<string[]>(
    preSelectedOptions,
    handleOnChange,
  );
  const [optionsList, setOptionsList] = useState(getOptionsList(options, selectedOptions));

  const onSelectOption = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, checked },
      } = e;
      let values: string[] = [];

      if (checked) {
        values = multiselect ? [...selectedOptions, value] : [value];
      } else if (!checked && multiselect) {
        values = selectedOptions.filter(current => current !== value);
      }
      setSelectedOptions(values);
    },
    [selectedOptions, setSelectedOptions, multiselect],
  );

  useEffect(() => {
    setOptionsList(getOptionsList(options, selectedOptions));
  }, [setOptionsList, options, selectedOptions]);

  return [optionsList, onSelectOption];
};
