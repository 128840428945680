import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import { theme, customScroll } from 'src/styles/theme';

export const StyledCategoryLabel = styled.div`
  font-weight: ${theme.typography.fontWeight.semiBold};
  font-size: ${theme.typography.fontSize.xs};
  color: ${theme.colors.disable};
  text-transform: uppercase;
  line-height: 16px;
  margin: 25px 30px 7px;

  svg {
    margin-left: 6px;
    vertical-align: 1px;
    transition: transform 0.2s ease-out;

    path {
      stroke: ${theme.colors.disable};
    }

    &.rotate {
      transform: rotate(-180deg);
    }
  }

  @media ${theme.device.laptop} {
    display: none;
  }
`;

export const StyledProgressLine = styled.div`
  width: 100%;
  position: relative;
  background: ${theme.colors.lightGray};
  height: 3px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    left: calc(50% - 7px);
    top: -5px;
    background-color: ${theme.colors.lightGray};
    border-radius: 50%;
  }
`;

const activeGradient = `linear-gradient(90deg, ${theme.colors.primary} 0%, ${theme.colors.primary} 45%, ${theme.colors.lightGray} 50%)`;

export const StyledContainer = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  width: -webkit-fill-available;
  pointer-events: none;

  &.visited {
    pointer-events: initial;
    cursor: pointer;

    ${StyledCategoryLabel} {
      color: ${theme.colors.dark};

      path {
        stroke: ${theme.colors.dark};
      }
    }

    ${StyledProgressLine} {
      background: ${theme.colors.primary};

      &::after {
        background-color: ${theme.colors.primary};
      }
    }

    @media ${theme.device.laptop} {
      pointer-events: none;
    }
  }

  &.active {
    ${StyledProgressLine} {
      background: ${activeGradient};
    }
  }
`;

const fadeInAnimation = keyframes`${fadeIn}`;

export const StyledQuestionList = styled.ul`
  position: absolute;
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  z-index: 1;
  top: 100%;
  width: 100%;
  cursor: initial;
  animation: backwards 0.3s ${fadeInAnimation};

  max-height: 300px;
  overflow-y: scroll;
  ${customScroll}
`;

export const StyledQuestionItem = styled.li`
  color: ${theme.colors.disable};
  font-size: ${theme.typography.fontSize.s};
  font-weight: ${theme.typography.fontWeight.medium};
  line-height: 24px;
  padding: 12px 20px;
  border-bottom: 1px solid ${theme.colors.lightGray};
  pointer-events: none;
  opacity: 0.7;

  &:last-child {
    border-bottom: none;
  }

  &.visited {
    color: ${theme.colors.dark};
    cursor: pointer;
    pointer-events: initial;

    &:hover {
      opacity: 1;
    }
  }
`;
