import React, { useCallback } from 'react';
import classnames from 'classnames';
import { NavCategory } from 'types/store';

import { CalculatorNavItem } from 'molecules';

import { StyledContainer } from './CalculatorNav.styles';
import { CalculatorNavProps } from './CalculatorNav.types';

const CalculatorNav: React.FC<CalculatorNavProps> = ({
  categories,
  currentCategoryIndex,
  lastVisitedCategory,
  moveTo,
}) => {
  const renderCategories = useCallback(
    ({ visited, id, questions, label }: NavCategory, activeCategory: boolean) => {
      const classNames = classnames({
        visited: visited,
        active: activeCategory,
      });

      return (
        <CalculatorNavItem key={id} className={classNames} questions={questions} moveTo={moveTo}>
          {label}
        </CalculatorNavItem>
      );
    },
    [categories, currentCategoryIndex],
  );

  return (
    <StyledContainer>
      {categories.map((category, i) => renderCategories(category, lastVisitedCategory === i))}
    </StyledContainer>
  );
};

export default CalculatorNav;
